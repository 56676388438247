/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import { reactStringReplace } from 'utils/reactStringReplace';
import { getLanguage } from 'utils/getLanguage';
import enDefault from './localization/en.json';

const getJson = () => {
  const acceptedLanguage = getLanguage();

  if (acceptedLanguage) {
    try {
      const file = require(`./localization/${acceptedLanguage}.json`);
      return file;
    } catch (error) {
      return enDefault;
    }
  }
  return enDefault;
};

function isClassComponent(component) {
  return (
    typeof component === 'function' && !!component.prototype.isReactComponent
  );
}

function isFunctionComponent(component) {
  return (
    typeof component === 'function' &&
    String(component).includes('return React.createElement')
  );
}

function isReactComponent(component) {
  return isClassComponent(component) || isFunctionComponent(component);
}

function isElement(element) {
  return React.isValidElement(element);
}

function isDOMTypeElement(element) {
  return isElement(element) && typeof element.type === 'string';
}

function isCompositeTypeElement(element) {
  return isElement(element) && typeof element.type === 'function';
}

export default (key, ...params) => {
  let value = getJson()[key];

  if (value && params) {
    params.forEach((param, index) => {
      if (
        isClassComponent(param) ||
        isFunctionComponent(param) ||
        isReactComponent(param) ||
        isElement(param) ||
        isDOMTypeElement(param) ||
        isCompositeTypeElement(param)
      ) {
        value = reactStringReplace(value, `{${index}}`, () => param);
      } else {
        value = value.replace(`{${index}}`, param);
      }
    });
  }

  if (process.env.NODE_ENV === 'development' && !value) {
    return 'LOCALIZABLE STRING IS NOT DEFINED!';
  }

  if (Array.isArray(value)) {
    return value.map((el, index) => {
      if (el.key) {
        return el;
      }

      if (Array.isArray(el)) {
        return el.map((_innerElement, innerIndex) => (
          <React.Fragment key={`translate-${index + 1}-${innerIndex + 1}`}>
            {_innerElement}
          </React.Fragment>
        ));
      }

      return isClassComponent(el) ||
        isFunctionComponent(el) ||
        isReactComponent(el) ||
        isElement(el) ||
        isDOMTypeElement(el) ||
        isCompositeTypeElement(el) ? (
        React.cloneElement(el, { key: `translate-${index + 1}` })
      ) : (
        <React.Fragment key={`translate-${index + 1}`}>{el}</React.Fragment>
      );
    });
  }

  return value;
};
