import { BodyText } from 'components/UI/Brand/text';
import { AlertBox } from 'components';
import Icon from 'components/UI/Icons/Icon';
import { TIcon } from 'components/UI/Icons/Icon.type';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface ICabinGuestSelectionAlertBox {
  message: string;
  iconType?: TIcon;
  type?: AlertType;
  dataTestId?: string;
}

type AlertType = 'success' | 'error' | 'warning' | 'info';

export const CabinGuestSelectionAlertBox = ({
  message,
  iconType = 'circleWithExclamation',
  type = 'info',
  dataTestId,
}: ICabinGuestSelectionAlertBox) => (
  <div className={styles.alertBoxContainer} data-testid={dataTestId}>
    <AlertBox
      aria-live="assertive"
      type={type}
      OverrideIcon={
        <Icon
          iconType={iconType as TIcon}
          color={colors.shared.textBlack}
          width={20}
          height={20}
        />
      }
      className={styles.alertBoxMessage}
    >
      <BodyText>{message}</BodyText>
    </AlertBox>
  </div>
);
