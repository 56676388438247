/* eslint-disable no-restricted-syntax */
import { getBookingStatus } from 'containers/Bookings/utils/getBookingStatus';
import { format } from 'date-fns';
import { colors } from 'style/colors';
import translate from 'translate';
import { getCalendarLocale } from 'utils/dates';
import { getFullDateFormat } from 'utils/multilanguage-support';

interface IGetRenderStatusInfoProps {
  balance: number;
  departureDate: string;
  depositAmount: number;
  status: string;
  totalPaymentsReceived: number;
  isCreditAgency: boolean;
  daysLeft: number | null;
  payDate: string;
  language: string;
}

export const getRenderStatusInfo = ({
  balance,
  departureDate,
  depositAmount,
  status,
  totalPaymentsReceived,
  isCreditAgency,
  daysLeft,
  payDate,
  language,
}: IGetRenderStatusInfoProps) => {
  const locale = getCalendarLocale(language);
  const {
    isManualHold,
    isCancelled,
    isDepositPending,
    isFullyPaid,
    isPartlyPaid,
    isPaymentPending,
    isSailed,
  } = getBookingStatus(
    balance,
    departureDate,
    depositAmount,
    status,
    totalPaymentsReceived
  );

  const statusConfig = [
    {
      condition: isManualHold,
      color: colors.shared.midnightYellow5,
      textKey: 'MyBookings_ManualHold',
    },
    {
      condition: isCancelled,
      color: colors.shared.warmGray5,
      textKey: 'MyBookings_Cancelled',
    },
    {
      condition: isSailed,
      color: colors.hrn.clearBlue1,
      textKey: 'MyBookings_Sailed',
    },
    {
      condition: isFullyPaid,
      color: colors.b2b.clearGreen,
      textKey: 'MyBookings_FullyPaid',
    },
    {
      condition: isCreditAgency,
      color: colors.b2b.clearGreen,
      textKey: 'General_BookingConfirmed',
    },
    {
      condition: isPartlyPaid,
      color: colors.b2b.clearBlue3,
      textKey: 'MyBookings_PartlyPaid',
    },
    {
      condition: isDepositPending,
      color: colors.shared.midnightYellow5,
      textKey: 'MyBookings_DepositPending',
    },
    {
      condition: isPaymentPending,
      color: colors.shared.midnightYellow5,
      textKey: 'MyBookings_PaymentPending',
    },
  ];

  let statusBgColor = colors.shared.warmGray6;
  let statusText = translate('MyBookings_Error');
  let isError = true;

  for (const { condition, color, textKey } of statusConfig) {
    if (condition) {
      statusBgColor = color;
      statusText = translate(textKey);
      isError = false;
      break;
    }
  }

  const infoTextColor = (() => {
    if (isCancelled || isError) return colors.shared.warmGray5;
    if (isSailed) return colors.hrn.clearBlue1;
    if (isFullyPaid) return colors.b2b.clearGreen;
    if (isPartlyPaid || (!isFullyPaid && daysLeft >= 14))
      return colors.b2b.clearBlue;
    if (isDepositPending) return colors.shared.midnightYellow5;
    return colors.shared.white;
  })();

  const deadlineMessage = (() => {
    if (isCancelled) return null;
    if (
      (isDepositPending || isPartlyPaid) &&
      daysLeft !== null &&
      daysLeft <= 0 &&
      !isFullyPaid
    ) {
      return translate('MyBookings_PaymentDeadlinePassed');
    }
    if (isDepositPending && daysLeft !== null && daysLeft > 0) {
      return translate('MyBookings_DaysLeftToPayDeposit', daysLeft);
    }
    if (isPartlyPaid && daysLeft !== null && daysLeft > 0) {
      return translate('MyBookings_DaysLeftToPayRemaining', daysLeft);
    }
    if (isFullyPaid && payDate) {
      return translate(
        'MyBookings_PaidOn',
        format(payDate, getFullDateFormat(), { locale })
      );
    }
    return null;
  })();

  return {
    statusBgColor,
    statusText,
    infoTextColor,
    deadlineMessage,
  };
};
