import { BodyText } from 'components/UI/Brand/text';
import {
  IExtraItemsData,
  IExtrasItemSummary,
  IExtrasType,
} from 'interfaces/IExtras';
import { format } from 'date-fns';
import {
  createExtrasItemData,
  getAmountOfBookedTravelers,
  isExtraAdded,
} from 'containers/Book/BookingProgress/BookingFunnel/Extras/utils/extras.util';
import { IRootState } from 'store/types/root';
import { useSelector } from 'react-redux';
import { getDayMonthYearWeekdayFormat } from 'utils/multilanguage-support';
import { getLanguage } from 'utils/getLanguage';
import { getCalendarLocale } from 'utils/dates';
import ExtraItem from './ExtraItem';
import styles from './styles.module.scss';

export const ExtraExcursionsItems = ({
  extras,
  numberOfViableExtrasItems,
  onRemoveExtra,
  caption,
  onOpenAddExtraModal,
}: {
  extras: IExtrasItemSummary[];
  numberOfViableExtrasItems: number;
  onRemoveExtra: (id: string, title: string, type: string) => void;
  caption: string;
  onOpenAddExtraModal: (data: IExtraItemsData, isEdit: boolean) => void;
}) => {
  const { extrasData } = useSelector((state: IRootState) => state.extras);
  const language = getLanguage();
  const locale = getCalendarLocale(language);

  const mappedExcursions = Object.entries(
    extras
      .sort((a, b) => a.startDateTime.localeCompare(b.startDateTime))
      .slice(0, numberOfViableExtrasItems)
      .reduce(
        (
          result: { [key: string]: IExtrasItemSummary[] },
          extra: IExtrasItemSummary
        ) => {
          const key = extra.startDateTime.split('T')[0];
          return {
            ...result,
            [key]: [...(result[key] || []), extra],
          };
        },
        {} as { [key: string]: IExtrasItemSummary[] }
      )
  ).map(([, excursions]) => excursions);

  return (
    <>
      {mappedExcursions.map((mappedExcursion, i) => {
        const formattedTitle = format(
          new Date(mappedExcursion?.[0].startDateTime),
          getDayMonthYearWeekdayFormat(),
          { locale }
        );

        return (
          <fieldset className={styles.extraExcursionsItems}>
            <BodyText id={`excursion-date-${i}`}>{formattedTitle}</BodyText>
            <ul
              key={`list-of-excursions-by-date-${i + 1}`}
              aria-labelledby={`excursion-date-${i}`}
              className={styles.internalList}
            >
              {mappedExcursion.map((excursionItem, index) => (
                <li key={excursionItem.excursionId}>
                  <ExtraItem
                    index={1 + index}
                    extraItem={createExtrasItemData(
                      excursionItem,
                      IExtrasType.Excursion,
                      caption
                    )}
                    onOpenAddExtraModal={onOpenAddExtraModal}
                    isAdded={isExtraAdded(
                      extrasData,
                      excursionItem.excursionId
                    )}
                    onRemoveExtra={() => {
                      onRemoveExtra(
                        excursionItem.excursionId,
                        excursionItem.title || excursionItem.description,
                        IExtrasType.Excursion
                      );
                    }}
                    amountOfBookedTravelers={getAmountOfBookedTravelers(
                      extrasData,
                      excursionItem.excursionId
                    )}
                  />
                </li>
              ))}
            </ul>
          </fieldset>
        );
      })}
    </>
  );
};
