import { handleActions } from 'redux-actions';
import * as actions from '../actions/profile';

const initialState = {
  profileData: null,
  error: null,
  loading: false,
  profileImageUrl: null,
  isProfileImageUrlLoading: false,
  isPasswordSaveLoading: false,
  isPasswordSave: false,
};

const reducer = handleActions(
  {
    [actions.fetchProfileDataStart]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [actions.fetchProfileDataSuccess]: (state, action) => ({
      ...state,
      profileData: action.payload.profileData,
      error: null,
      loading: false,
    }),
    [actions.fetchProfileDataFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [actions.updateProfileData]: (state, action) => ({
      ...state,
      profileData: {
        ...state.profileData,
        ...action.payload.profileData,
      },
    }),
    [actions.clearProfileDataStart]: () => ({
      ...initialState,
    }),
    [actions.updateProfileAgencyStart]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [actions.updateProfileAgencySuccess]: (state, action) => ({
      ...state,
      error: null,
      profileData: {
        ...state.profileData,
        agency: {
          ...state.profileData.agency,
          ...action.payload.agencyData,
        },
      },
      loading: false,
    }),
    [actions.updateProfileAgencyFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [actions.fetchProfileImageStart]: (state) => ({
      ...state,
      isProfileImageUrlLoading: true,
      error: null,
    }),
    [actions.fetchProfileImageSuccess]: (state, action) => ({
      ...state,
      profileImageUrl: action.payload.profileImageUrl,
      error: null,
      isProfileImageUrlLoading: false,
    }),
    [actions.fetchProfileImageFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isProfileImageUrlLoading: false,
    }),
    [actions.savePasswordStart]: (state) => ({
      ...state,
      isPasswordSaveLoading: true,
      error: null,
    }),
    [actions.savePasswordSuccess]: (state) => ({
      ...state,
      error: null,
      isPasswordSaveLoading: false,
      isPasswordSave: true,
    }),
    [actions.savePasswordFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isPasswordSaveLoading: false,
    }),
  },
  initialState
);

export default reducer;
