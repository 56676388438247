import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import HurtigrutenLogo from 'components/UI/Logo/HurtigrutenLogo';
import { Logo } from 'components/UI/Logo/util';
import { AlertInfoBanner } from 'components';
import translate from 'translate';
import { track } from 'utils/analytics';
import { hasValidToken } from 'utils/getToken';
import HxLogo from 'components/UI/Logo/HxLogo';
import { Region, getRegionByLocale } from 'utils/regions';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';
import NavItemsLeft from './Navbar/NavItemsLeft';
import NavItemsRight from './Navbar/NavItemsRight';
import SignupLoginButtonGroup from './SignupLoginButtonGroup/SignupLoginButtonGroup';
import SplitHeader from './SplitHeader';

interface IStyledProps {
  fixed: boolean;
  isFilledHeader?: boolean;
  hasAlertBanner: boolean;
  hasSplitBanner: boolean;
}

interface IHeaderProps extends IStyledProps {
  isAuth: boolean;
  setLearnButtonActive: (value: boolean) => void;
  setShowLearnNavigation: (value: boolean) => void;
}

const { REACT_APP_COMPANY } = process.env;

const trackBook = () => track('Click book');

const showNavigationLinks = () => {
  const pathName: string = useLocation().pathname;

  if (
    pathName.includes('mybookingspayment') ||
    pathName.includes('book/progress/payment')
  ) {
    return false;
  }

  return true;
};

const MainHeader = (props: IHeaderProps) => {
  const {
    isAuth,
    setLearnButtonActive,
    setShowLearnNavigation,
    isFilledHeader = false,
    hasAlertBanner,
    hasSplitBanner,
    fixed,
  } = props;
  const region = getRegionByLocale(getLanguage());

  const germanRegions =
    region === Region.Germany ||
    region === Region.Austria ||
    region === Region.Switzerland;

  return (
    <div className={styles.mainHeader}>
      <div
        className={clsx(styles.container, {
          [styles.fixed]: fixed,
          [styles.hasAlertBanner]: hasAlertBanner,
          [styles.isFilledHeader]: isFilledHeader,
        })}
      >
        {hasAlertBanner && <AlertInfoBanner />}
        {hasSplitBanner && (!germanRegions || REACT_APP_COMPANY !== 'HRN') && (
          <SplitHeader />
        )}
        <header className={styles.header} data-testid="main-header">
          <nav className={styles.navigation}>
            <Link
              to={
                hasValidToken()
                  ? REACT_APP_COMPANY === 'HRN'
                    ? '/book/coastal'
                    : '/book/expedition'
                  : '/'
              }
              className={styles.logo}
              aria-label={translate('Navbar_Home')}
            >
              <div
                className={styles.logoContainer}
                onClick={() => isAuth && trackBook()}
              >
                {REACT_APP_COMPANY === 'HX' && (
                  <HxLogo
                    logoType={
                      isFilledHeader ? Logo.MAIN_HX_BLUE : Logo.MAIN_WHITE_FONT
                    }
                  />
                )}
                {(REACT_APP_COMPANY === 'HRN' || !REACT_APP_COMPANY) && (
                  <HurtigrutenLogo
                    logoType={
                      isFilledHeader
                        ? Logo.MAIN_BLACK_FONT
                        : Logo.MAIN_WHITE_FONT
                    }
                  />
                )}
              </div>
            </Link>
            {showNavigationLinks() &&
              (isAuth ? (
                <div className={styles.headerContainer}>
                  <NavItemsLeft
                    setShowLearnNavigation={setShowLearnNavigation}
                    setLearnButtonActive={setLearnButtonActive}
                  />
                  <NavItemsRight
                    setShowLearnNavigation={setShowLearnNavigation}
                  />
                </div>
              ) : (
                <SignupLoginButtonGroup
                  isFilledHeader={isFilledHeader}
                  isMobile={false}
                />
              ))}
          </nav>
        </header>
      </div>
    </div>
  );
};

export default MainHeader;
