import { InfoText } from 'components/UI/Brand/text';
import translate from 'translate';
import styles from './styles.module.scss';

interface IInformationBox {
  infoText: string;
  onClick: () => void;
}

const InformationBox = ({ infoText, onClick }: IInformationBox) => (
  <div className={styles.root}>
    <div className={styles.infoBoxWithTopArrow}>
      <InfoText>{infoText}</InfoText>
      <div className={styles.closeButtonContainer}>
        <button className={styles.closeButton} onClick={onClick}>
          {translate('Search_MobileSearchBar_Close')}
        </button>
      </div>
    </div>
  </div>
);

export default InformationBox;
