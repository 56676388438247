import { InfoText } from 'components/UI/Brand/text';
import translate from 'translate';
import { getCurrencyFormat } from 'utils/multilanguage-support';
import getLocaleNumberFormat from 'utils/numberWithCommas';
import styles from './styles.module.scss';

interface IStrikeThroughPriceProps {
  strikeThroughPrice: number;
  price: number | string;
}

export const StrikeThroughPrice = ({
  strikeThroughPrice,
  price,
}: IStrikeThroughPriceProps) => (
  <>
    {strikeThroughPrice && strikeThroughPrice > +price && (
      <InfoText className={styles.strikeThroughPrice}>
        {`${translate('General_From')} 
          ${
            Number.isInteger(strikeThroughPrice)
              ? translate(
                  'SearchResultItemRow_Price',
                  getCurrencyFormat(),
                  getLocaleNumberFormat(strikeThroughPrice)
                )
              : strikeThroughPrice
          }
          `}
      </InfoText>
    )}
  </>
);
