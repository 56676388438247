import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header4, InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { useModal } from 'hooks/useModal';
import { fetchInfoPopup, fetchProfileData } from 'store/actions';
import axios from 'axios-instance';
import { hasValidToken, getToken } from 'utils/getToken';
import translate from 'translate';
import { IRootState } from 'store/types/root';
import { IFooterData, ILink } from 'interfaces/IFooter';
import { onFooterData } from 'store/actions/home';
import Icon from 'components/UI/Icons/Icon';
import { TIcon } from 'components/UI/Icons/Icon.type';
import { LinkText } from 'components';
import { useAppDispatch } from 'hooks/storeHooks';
import { WHITE_LIST_LINKS } from 'utils/constants';
import { Link } from 'react-router-dom';
import OnboardingModal from '../Modals/OnBoardingModal/OnboardingModal';
import { Logo } from '../Logo/util';
import HurtigrutenLogo from '../Logo/HurtigrutenLogo';
import Award from './Award';
import styles from './styles.module.scss';

const MainFooter = () => {
  const [footerData, setFooterData] = useState<IFooterData>(null);
  const auth = getToken();
  const publicIp = useSelector((state: IRootState) => state.home.publicIp);
  const { isModalVisible, showModal, hideModal, RenderModal } = useModal();
  const hasValidTokenId = hasValidToken();
  const dispatch = useAppDispatch();
  const { REACT_APP_COMPANY } = process.env;

  const getFooterData = () => {
    let companyTag = REACT_APP_COMPANY;

    if (companyTag === 'HX') {
      companyTag = 'HRX';
    } else if (!companyTag) {
      companyTag = 'HRG';
    }

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}footer`, {
        headers: { 'x-fallback-ip': publicIp },
        params: { tag: companyTag.toLowerCase() },
      })
      .then((res) => {
        setFooterData(res.data);
        dispatch(onFooterData(res.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    getFooterData();
  }, [publicIp, auth]);

  useEffect(() => {
    if (hasValidTokenId) {
      dispatch(fetchInfoPopup());
      dispatch(fetchProfileData());
    }
  }, [hasValidTokenId]);
  const phoneNumbers = (
    <div>
      <Header4 id="phone-numbers">{footerData?.phoneTitle}</Header4>
      <ul aria-labelledby="phone-numbers" className={styles.list}>
        {footerData?.phone?.map((phoneItem: string) => {
          const number = phoneItem.replace(/\D+/g, '');
          return (
            <li key={number.replace(/\s/g, '')}>
              <LinkText href={`tel:+${number}`} key={phoneItem}>
                {phoneItem}
              </LinkText>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const emails = (
    <div>
      <Header4 id="emails">{footerData?.emailTitle}</Header4>
      <ul aria-labelledby="emails" className={styles.list}>
        {footerData?.email?.map((emailItem: string) => (
          <li key={emailItem.replace(/\s/g, '')}>
            <LinkText href={`mailto:${emailItem}`}>{emailItem}</LinkText>
          </li>
        ))}
      </ul>
    </div>
  );

  const mailingAddresses = (
    <div>
      <Header4>{footerData?.addressTitle}</Header4>
      <ul aria-label="mailing-addresses" className={styles.list}>
        {footerData?.mailingAddress?.map((adressItem: string) => (
          <div key={adressItem.replace(/\s/g, '')}>
            <LinkText
              href={`http://maps.google.com/?q=1505 ${adressItem}`}
              target="blank"
            >
              {adressItem}
            </LinkText>
          </div>
        ))}
      </ul>
    </div>
  );

  const whiteListShortCuts = hasValidTokenId
    ? footerData?.otherLinks
    : footerData?.otherLinks?.filter((link: ILink) =>
        WHITE_LIST_LINKS.includes(link.hyperlink)
      );

  const shortCuts = (
    <div>
      <Header4 id="short-cuts">{footerData?.otherLinksTitle}</Header4>
      <ul aria-labelledby="short-cuts" className={styles.list}>
        {whiteListShortCuts?.map(({ hyperlink, title }: ILink) => (
          <li key={title.replace(/\s/g, '')}>
            {hyperlink.startsWith('https://') ? (
              <a href={hyperlink}>{title}</a>
            ) : (
              <LinkText to={hyperlink} as={Link} key={hyperlink}>
                {title}
              </LinkText>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  const socialMediaLinks = (
    <div>
      <Header4 id="social-meida">{footerData?.socialMediaTitle}</Header4>
      <ul
        aria-labelledby="social-media"
        className={clsx(styles.list, styles.soMe)}
      >
        {footerData?.socialMedia?.map(({ title, hyperlink }: ILink) => (
          <li key={title.replace(/\s/g, '')}>
            <a title={title} href={hyperlink} rel="noreferrer" target="_blank">
              <Icon
                height={32}
                width={32}
                iconType={title.toLocaleLowerCase() as TIcon}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  if (!footerData) return null;

  const awards = footerData?.awards?.filter((element) => element !== null);
  return (
    <div>
      {!!awards.length && hasValidTokenId && (
        <div className={styles.awardsContainer}>
          <ul aria-labelledby="awards" className={styles.awards}>
            {awards.map(({ title, image, description, awardLink }) => (
              <Award
                title={title}
                image={image}
                description={description}
                awardLink={awardLink}
                key={image.url}
              />
            ))}
          </ul>
        </div>
      )}
      <footer id="mainFooter" className={styles.root}>
        <div className={styles.container}>
          <section className={styles.column}>
            <div>
              <HurtigrutenLogo logoType={Logo.MAIN_WHITE} />
              <InfoText>{translate('Footer_Hurtigruten')}</InfoText>
            </div>
          </section>
          <section className={styles.column}>
            {phoneNumbers}
            {emails}
            {mailingAddresses}
          </section>
          <section className={styles.column}>
            {shortCuts}
            {hasValidTokenId && (
              <div>
                <Header4>{translate('General_Help')}:</Header4>
                <button className={styles.buttonAsLink} onClick={showModal}>
                  {translate('OnBoarding_AgentPortalGuide')}
                </button>
              </div>
            )}
          </section>
          <section className={styles.column}>{socialMediaLinks}</section>
        </div>
        {isModalVisible && (
          <RenderModal>
            <OnboardingModal hideModal={hideModal} />
          </RenderModal>
        )}
      </footer>
    </div>
  );
};

export default MainFooter;
