import { createActions, Action, ActionFunctionAny } from 'redux-actions';
import { AxiosError } from 'axios';
import axios from '../../axios-instance';
import { IValidationValues } from '../../interfaces/IOnboarding';

export const {
  resetOnboardData,
  saveOnboardData,
  searchAgent,
  fetchLeadValuesStart,
  fetchLeadValuesSuccess,
  fetchLeadValuesFailed,
  fetchAgentValuesStart,
  fetchAgentValuesSuccess,
  fetchAgentValuesFailed,
  fetchAgencyValuesStart,
  fetchAgencyValuesSuccess,
  fetchAgencyValuesFailed,
} = createActions({
  RESET_ONBOARD_DATA: undefined,
  SAVE_ONBOARD_DATA: undefined,
  SEARCH_AGENT: undefined,
  FETCH_LEAD_VALUES_START: undefined,
  FETCH_LEAD_VALUES_SUCCESS: (values) => ({ values }),
  FETCH_LEAD_VALUES_FAILED: (error) => ({ error }),
  FETCH_AGENT_VALUES_START: undefined,
  FETCH_AGENT_VALUES_SUCCESS: (values) => ({ values }),
  FETCH_AGENT_VALUES_FAILED: (error) => ({ error }),
  FETCH_AGENCY_VALUES_START: undefined,
  FETCH_AGENCY_VALUES_SUCCESS: (values) => ({ values }),
  FETCH_AGENCY_VALUES_FAILED: (error) => ({ error }),
});

export const fetchValidLeadValues =
  () =>
  (dispatch: ActionFunctionAny<Action<IValidationValues | AxiosError>>) => {
    dispatch(fetchLeadValuesStart());
    axios
      .get<IValidationValues>(
        `${process.env.REACT_APP_API_BASE_URL}validation/lead/values`
      )
      .then((res) => {
        dispatch(fetchLeadValuesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchLeadValuesFailed(err));
      });
  };

export const fetchValidAgentValues =
  () =>
  (dispatch: ActionFunctionAny<Action<IValidationValues | AxiosError>>) => {
    dispatch(fetchAgentValuesStart());
    axios
      .get<IValidationValues>(
        `${process.env.REACT_APP_API_BASE_URL}validation/agent/values`
      )
      .then((res) => {
        dispatch(fetchAgentValuesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchAgentValuesFailed(err));
      });
  };

export const fetchValidAgencyValues =
  () =>
  (dispatch: ActionFunctionAny<Action<IValidationValues | AxiosError>>) => {
    dispatch(fetchAgencyValuesStart());
    axios
      .get<IValidationValues>(
        `${process.env.REACT_APP_API_BASE_URL}validation/agency/values`
      )
      .then((res) => {
        dispatch(fetchAgencyValuesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchAgencyValuesFailed(err));
      });
  };
