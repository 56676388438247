import clsx from 'clsx';
import { useState, useEffect, useRef, ChangeEvent, useCallback } from 'react';
import { useParams } from 'react-router';
import RadioOptionGroup from 'components/UI/RadioOptionGroup/RadioOptionGroup';
import { InfoText, BodyText, LabelText } from 'components/UI/Brand/text';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import CustomLink from 'components/UI/CustomLink/CustomLink';
import translate from 'translate';
import InformationIcon from 'components/UI/InformationIcon/InformationIcon';
import {
  setIsDeckSearch,
  setShowAccessibleCabins,
  updateCabinToSearch,
  addCabinToSearch,
  removeCabinFromSearch,
} from 'store/actions';
import { getCabinValidationMessage } from 'utils/search';
import {
  DEFAULT_DECKSPACE_MAX_GUESTS,
  isSearchTypeCoastal,
  MAX_PASSENGERS_CABIN,
  MAX_PASSENGERS_DECKSPACE,
  SEARCH_TYPE,
} from 'utils/constants';
import Icon from 'components/UI/Icons/Icon';
import { ICabinBaseSelector } from 'interfaces/ICabin';
import localStorageKeys from 'utils/localStorageKeys';
import { useClickOutside } from 'hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { FlatButton } from 'components/UI/Buttons/FlatButton/FlatButton';
import { getTotalPasssengersInCabin } from 'utils/getTotalPeopleInCabin';
import { generatePassengerOverviewLabel } from 'utils/generatePassengerOverviewLabel';
import { CabinGuestSelectionAlertBox } from 'components/UI/CabinGuestSelectionAlertBox/CabinGuestSelectionAlertBox';
import { colors } from 'style/colors';
import { CabinsSelector } from '../CabinsSelector';
import PassengersSelector from '../PassengersSelector';
import { isOverCabinLimit } from '../utils/cabinLimit';
import { CabinDiscountOverlay } from '../CabinDiscountOverlay';
import { isNoMarket, isNotUSorAPAC } from '../utils/constants';
import { BenefitLink } from '../BenefitLink';
import styles from './styles.module.scss';

export enum bookCabinOptionValues {
  BookCabin = 'bookCabin',
  WithoutCabin = 'withoutCabin',
}

export interface ICabinGuestSelectorProps {
  isDeckSpaceBookingEnabled?: boolean;
  onDeckSelected?: (deckPassengers: ICabinBaseSelector) => void;
  isP2P?: boolean;
  deckPassengers?: ICabinBaseSelector;
  showAccessibleModal: () => void;
  showPromotionCodeModal: () => void;
}

const CabinGuestSelector = ({
  showAccessibleModal,
  isDeckSpaceBookingEnabled = false,
  onDeckSelected,
  deckPassengers,
  isP2P = false,
  showPromotionCodeModal,
}: ICabinGuestSelectorProps) => {
  const { productType } = useParams<{ productType?: string }>();
  const { isOnlyDeckSpaceAvailable, isDeckSpaceAndCabinAvailable } =
    useAppSelector((state) => state.ports);

  const initSearchCabins = useAppSelector((state) => state.search.cabins);

  const { cabins, promotionCode } = useAppSelector((state) => state.search);

  const isOnlyCabinAvailable =
    !isOnlyDeckSpaceAvailable && !isDeckSpaceAndCabinAvailable;

  const [isOpen, setOpen] = useState(false);

  const [isAccessibilityEnabled, setAccessibilityEnabled] = useState(
    initSearchCabins?.[0]?.accessibility
  );

  useEffect(() => {
    setAccessibilityEnabled(initSearchCabins?.[0]?.accessibility);
  }, [initSearchCabins]);

  const [bookCabinOption, setBookCabinOption] = useState<string>(
    bookCabinOptionValues.BookCabin
  );
  const [maxPassengers, setMaxPassengers] = useState(
    DEFAULT_DECKSPACE_MAX_GUESTS
  );
  const [cabinValidationMessage, setCabinValidationMessage] = useState('');

  const isCoastal = isSearchTypeCoastal(
    productType?.toUpperCase() as SEARCH_TYPE
  );

  const dispatch = useAppDispatch();

  const cabinGuestControlRef = useRef(null);
  useClickOutside(cabinGuestControlRef, () => setOpen(false));
  const supportPhone = localStorage.getItem(localStorageKeys.supportPhone);
  const hasCabins = bookCabinOption === bookCabinOptionValues.BookCabin;
  const placeholder = generatePassengerOverviewLabel({
    cabins,
    passengers: deckPassengers,
    hasCabins,
  });
  const { totalPassengers, totalInfants } = getTotalPasssengersInCabin(
    cabins,
    false
  );
  const isCabinSizeNotificationDisplay =
    Math.ceil(totalPassengers / 2) > cabins?.length && hasCabins;
  const isInfantsBedNotificationDisplay =
    totalInfants > 0 && isCoastal && hasCabins;
  const isAccessibilityNotificationDisplay =
    bookCabinOption === bookCabinOptionValues.WithoutCabin &&
    isDeckSpaceBookingEnabled;

  const setAccessibility = (value: boolean) => {
    const updatedCabins = cabins?.map((cabin) => ({
      ...cabin,
      accessibility: value,
    }));
    updatedCabins.forEach((cabin) => {
      dispatch(updateCabinToSearch({ cabin, isForceUpdate: false }));
    });
    setAccessibilityEnabled(value);
    dispatch(setShowAccessibleCabins(value));
  };

  const addCabin = () => {
    if (isOverCabinLimit(cabins.length, isP2P)) return;
    dispatch(addCabinToSearch());
  };

  const removeCabin = (cabinId: string) => {
    dispatch(removeCabinFromSearch(cabinId));
  };

  const onCabinValidationCheck = () => {
    setCabinValidationMessage(getCabinValidationMessage(cabins));
  };

  const handleGuestIncrement = (
    field: string,
    value: string,
    cabinId: string
  ) => {
    let updatedCabin = {
      ...cabins.find((cabin) => cabin.id === cabinId),
      [field]: Number(value),
    };

    if (
      value === '0' &&
      field === 'benefitFares' &&
      updatedCabin.companions !== 0
    ) {
      updatedCabin = {
        ...updatedCabin,
        companions: 0,
        maxCompanions: MAX_PASSENGERS_CABIN,
      };
    }

    const { totalPassengers: total } = getTotalPasssengersInCabin([
      updatedCabin,
    ]);

    const updatedCabinWithMaxPax = {
      ...updatedCabin,
      maxAdults: MAX_PASSENGERS_CABIN - (total - updatedCabin.adults),
      maxChildren: MAX_PASSENGERS_CABIN - (total - updatedCabin.children),
      maxInfants: MAX_PASSENGERS_CABIN - (total - updatedCabin.infants),
      maxBenefitFares:
        MAX_PASSENGERS_CABIN - (total - updatedCabin.benefitFares),
      maxCompanions: MAX_PASSENGERS_CABIN - (total - updatedCabin.companions),
      maxMilitaries: MAX_PASSENGERS_CABIN - (total - updatedCabin.militaries),
      maxStudents: MAX_PASSENGERS_CABIN - (total - updatedCabin.students),
    };

    dispatch(
      updateCabinToSearch({
        cabin: updatedCabinWithMaxPax,
        isForceUpdate: false,
      })
    );
  };

  const handleDeckPassengersIncrement = (field: string, value: string) => {
    let modifiedDeckPassengers = {
      ...deckPassengers,
      [field]: Number(value),
    };

    if (
      value === '0' &&
      field === 'benefitFares' &&
      modifiedDeckPassengers.companions !== 0
    ) {
      modifiedDeckPassengers = {
        ...modifiedDeckPassengers,
        companions: 0,
      };
    }

    const { totalPassengers: total } = getTotalPasssengersInCabin([
      modifiedDeckPassengers,
    ]);

    setMaxPassengers({
      ...modifiedDeckPassengers,
      adults:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.adults),
      children:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.children),
      infants:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.infants),
      benefitFares:
        MAX_PASSENGERS_DECKSPACE -
        (total - modifiedDeckPassengers.benefitFares),
      companions:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.companions),
      militaries:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.militaries),
      students:
        MAX_PASSENGERS_DECKSPACE - (total - modifiedDeckPassengers.students),
    });

    onDeckSelected({ ...modifiedDeckPassengers, [field]: Number(value) });
  };

  const getBookCabinOption = useCallback(() => {
    let cabinOption: bookCabinOptionValues = bookCabinOptionValues.BookCabin;
    if (!isDeckSpaceBookingEnabled) {
      cabinOption = bookCabinOptionValues.BookCabin;
    } else {
      cabinOption =
        isOnlyCabinAvailable && isDeckSpaceBookingEnabled
          ? bookCabinOptionValues.BookCabin
          : bookCabinOptionValues.WithoutCabin;
    }

    return cabinOption;
  }, [
    isDeckSpaceBookingEnabled,
    isOnlyCabinAvailable,
    isDeckSpaceBookingEnabled,
  ]);

  useEffect(() => {
    setBookCabinOption(getBookCabinOption());
  }, [isOnlyCabinAvailable, isDeckSpaceBookingEnabled]);

  useEffect(() => {
    dispatch(setIsDeckSearch(!hasCabins));
  }, [hasCabins]);

  useEffect(() => {
    onCabinValidationCheck();
  }, [cabins]);

  const isPromotionAdded = promotionCode && promotionCode !== '';

  const [openDiscount, setOpeDiscount] = useState(false);

  return (
    <>
      <div className={styles.root} ref={cabinGuestControlRef}>
        <button
          id="booking_search_bar_cabin"
          tabIndex={0}
          onClick={() => {
            setOpen(!isOpen);
            setOpeDiscount(false);
          }}
          className={styles.inputControl}
        >
          <div className={styles.customIcon}>
            <Icon
              iconType="user"
              width={16}
              height={16}
              color={colors.shared.warmGray5}
            />
          </div>
          <LabelText
            className={styles.valueContainer}
            data-testid={'input-placeholder'}
          >
            <span className={styles.truncateWrapper}>
              <span className={styles.truncate}>{placeholder}</span>
            </span>
          </LabelText>
          <div className={styles.customDropDownIcon}>
            <Icon
              iconType={!isOpen ? 'arrowDown' : 'arrowUp'}
              width={24}
              height={24}
            />
          </div>
        </button>
        {isOpen && (
          <div className={styles.cabinsCenter} id="cabin_guest_selector">
            {isDeckSpaceBookingEnabled && (
              <>
                {isOnlyDeckSpaceAvailable && (
                  <BodyText
                    className={styles.longVoyageNotification}
                    data-testid="book-deck-no-cabins-notice"
                  >
                    {translate('SearchBar_Only_Deck_Notice')}
                  </BodyText>
                )}
                {isOnlyCabinAvailable && (
                  <BodyText
                    className={styles.longVoyageNotification}
                    data-testid="book-cabin-long-voyage-notice"
                  >
                    {translate('SearchBar_Need_Cabin_Notice')}
                  </BodyText>
                )}

                {isNotUSorAPAC && !isDeckSpaceAndCabinAvailable && (
                  <BenefitLink
                    onClick={setOpeDiscount}
                    buttonText={translate('SearchBar_Overlay_Link')}
                  />
                )}
                {isDeckSpaceAndCabinAvailable && (
                  <div className={styles.topBar}>
                    <RadioOptionGroup
                      name="bookCabin"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setBookCabinOption(event.target.value)
                      }
                      className={styles.cabinRadioGroup}
                      defaultValue={bookCabinOption}
                      options={[
                        {
                          label: translate('SearchBar_Cabin_BookCabin_Option'),
                          value: bookCabinOptionValues.BookCabin,
                        },
                        {
                          label: translate(
                            'SearchBar_Cabin_WithoutCabin_Option'
                          ),
                          value: bookCabinOptionValues.WithoutCabin,
                        },
                      ]}
                    />
                    {isNotUSorAPAC && (
                      <BenefitLink
                        onClick={setOpeDiscount}
                        buttonText={translate('SearchBar_Overlay_Link')}
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {hasCabins ? (
              <CabinsSelector
                cabins={cabins}
                onGuestIncrement={handleGuestIncrement}
                removeCabin={removeCabin}
                addCabin={addCabin}
                isP2P={isP2P}
                isEU={isNotUSorAPAC}
                isNo={isNoMarket}
              />
            ) : (
              <PassengersSelector
                deckPassengers={deckPassengers}
                onDeckPassengersIncrement={handleDeckPassengersIncrement}
                maxPassengers={maxPassengers}
                isEU={isNotUSorAPAC}
                isNo={isNoMarket}
              />
            )}
            <div className={styles.accessibilityContainer}>
              {isAccessibilityNotificationDisplay ? (
                <CabinGuestSelectionAlertBox
                  message={translate('SearchBar_Notification_Accessibility')}
                  dataTestId="notification-accessibility"
                />
              ) : (
                <Checkbox
                  id="accessibility_checkbox"
                  defaultChecked={isAccessibilityEnabled}
                  onChange={() => {
                    setAccessibility(!isAccessibilityEnabled);
                  }}
                >
                  <LabelText>
                    {translate('SearchBar_CabinGuestSelector_Accessibility')}
                  </LabelText>
                  {bookCabinOption === bookCabinOptionValues.BookCabin && (
                    <InformationIcon
                      id="accessibility_info_modal"
                      onClick={showAccessibleModal}
                      height={20}
                      width={20}
                    />
                  )}
                </Checkbox>
              )}
            </div>
            {openDiscount ? (
              <CabinDiscountOverlay onClose={setOpeDiscount} />
            ) : null}
            {isCabinSizeNotificationDisplay && (
              <CabinGuestSelectionAlertBox
                message={translate('SearchBar_Notification_CabinSize')}
                iconType={'circleWithExclamation'}
                type="warning"
                dataTestId="notification-cabin-size"
              />
            )}
            {isInfantsBedNotificationDisplay && (
              <CabinGuestSelectionAlertBox
                message={translate(
                  'SearchBar_Notification_ChildrenBed',
                  supportPhone
                )}
                type="info"
              />
            )}
            <div
              className={styles.addPromotionContainer}
              style={isPromotionAdded ? { height: 70 } : {}}
            >
              {isPromotionAdded ? (
                <div className={styles.promoResultCntr}>
                  <div className={styles.promoResultLeft}>
                    <InfoText className={styles.promoCodeText}>
                      {translate('PromotionCodeModal_PromotionCode')}
                    </InfoText>
                    <BodyText>{promotionCode}</BodyText>
                  </div>
                  <div className={styles.promoResultRight}>
                    <CustomLink onClick={showPromotionCodeModal}>
                      {translate('PromotionCodeModal_Edit')}
                    </CustomLink>
                  </div>
                </div>
              ) : (
                <FlatButton
                  id="add_promotion_btn"
                  onClick={showPromotionCodeModal}
                >
                  {translate('PromotionCodeModal_AddPromoCode')}
                </FlatButton>
              )}
            </div>
          </div>
        )}
      </div>
      {cabinValidationMessage && (
        <InfoText
          className={clsx(styles.coastalTooltip, {
            [styles.isCoastal]: isCoastal,
          })}
          data-testid="cabins-selector__max-pax-warning"
        >
          {cabinValidationMessage}
        </InfoText>
      )}
    </>
  );
};
export default CabinGuestSelector;
