import { memo, ReactNode, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import styles from './styles.module.scss';

export const Modal = memo(
  ({ onClose, children }: { onClose?: () => void; children: ReactNode }) => {
    const domElement = document.getElementById('root');
    const modalRef = useRef(null);

    return (
      domElement &&
      createPortal(
        <FocusOn
          shards={[modalRef]}
          onClickOutside={onClose}
          onEscapeKey={onClose}
        >
          <div className={styles.useModal} aria-modal="true">
            <div className={styles.card}>{children}</div>
          </div>
        </FocusOn>,
        domElement
      )
    );
  }
);

export const useModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const RenderModal = ({ children }: { children: ReactNode }) => {
    useEffect(() => {
      const handleEsc = (event: KeyboardEvent) => {
        if (event.key === 'Escape') hideModal();
      };

      if (isModalVisible) window.addEventListener('keydown', handleEsc);

      return () => window.removeEventListener('keydown', handleEsc);
    }, [setModalVisible]);

    return isModalVisible && <Modal onClose={hideModal}>{children}</Modal>;
  };

  return {
    isModalVisible,
    showModal,
    hideModal,
    RenderModal,
  };
};
