import { FormEvent, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useAppSelector } from 'hooks/storeHooks';
import TextInput from 'components/UI/TextInput/TextInput';
import inputValidator from 'utils/inputValidator';
import debounce from 'utils/debounce';
import translate from 'translate';
import { RoundButtonLoadable } from 'components/UI/Buttons/RoundButtonLoadable/RoundButtonLoadable';
import { useNavigate } from 'react-router-dom';
import { DEBOUNCE_TIME } from 'utils/constants';
import { IAuthPayload } from 'interfaces/IAuth';
import { useAuthMutation } from 'store/services/auth';
import { AlertBox } from 'components';
import { BodyText, Header3 } from 'components/UI/Brand/text';
import { LOGIN_STATUSES } from '../LoginStatuses';
import LoginHelpBlock from '../LoginHelpBlock/LoginHelpBlock';
import styles from './styles.module.scss';

interface IEmailValidation {
  valid: boolean;
  errorMessage: string;
}
const validation = debounce(
  (
    setEmailValidation: (value: IEmailValidation) => void,
    emailValidation: IEmailValidation,
    name: string,
    value: string
  ) => {
    setEmailValidation({
      ...emailValidation,
      valid: inputValidator(name, value),
    });
  },
  DEBOUNCE_TIME
);

interface LoginFormProps {
  title: string;
  desc: string;
  setLoginState: (loginStateElement: LOGIN_STATUSES, email: string) => void;
}
const LoginForm = ({ title, desc, setLoginState }: LoginFormProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    errorMessage: translate('Login_EmailValidationError'),
  });

  const { error } = useAppSelector((state) => state.auth);
  const [auth, { isLoading }] = useAuthMutation();

  useEffect(() => {
    if (error === '401C') {
      navigate(`/emailconfirmation/${email}`);
      return;
    }
    if (error === '401M') {
      setLoginState(LOGIN_STATUSES.WAIT_EMAIL_VERIFICATION, email);
    }
    if (error) {
      document.getElementById('login_loginform_emailinput').focus();
    }
  }, [error]);

  const tryLogin = async () => {
    const params = queryString.parse(window.location.search);
    const authData: IAuthPayload = {
      email,
      password,
      temporaryAgentPGId: params.agentId as string,
      temporaryAgencyPGId: params.agencyId as string,
    };

    auth({ authData });
  };

  const onClickedButton = (event: FormEvent) => {
    event.preventDefault();
    if (isLoading) return;
    tryLogin();
  };

  const onSignupClicked = () => {
    navigate('/onboard');
  };

  const onForgotPasswordClicked = () => {
    navigate(`/forgetpassword/${email}`);
  };

  return (
    <div className={styles.root}>
      <Header3 className={styles.title}>{title}</Header3>
      <BodyText>{desc}</BodyText>
      <div className={styles.mainCntr}>
        <form className={styles.leftCntr} onSubmit={onClickedButton}>
          <div className={styles.inputCntr}>
            {error === '401SS' && (
              <AlertBox type="error">
                {translate('SeatchBar_StopSell')}
              </AlertBox>
            )}
            {error && (
              <span id="form_helper_text">
                {translate('Login_ErrorMessage')}
              </span>
            )}
            <TextInput
              name="email"
              id="login_loginform_emailinput"
              label={translate('Login_EmailLabel')}
              placeholder={translate('Login_EmailPlaceholder')}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={() => {
                if (email.length > 0) {
                  validation(
                    setEmailValidation,
                    emailValidation,
                    'email',
                    email
                  );
                }
              }}
              helperText={
                !emailValidation.valid
                  ? error
                    ? translate('Login_ErrorMessage')
                    : emailValidation.errorMessage
                  : null
              }
              error={!emailValidation.valid || error != null}
              isAutoFocus
            />
          </div>
          <div className={styles.inputCntr}>
            <TextInput
              isPassword
              id="login_loginform_passwordinput"
              name="password"
              label={translate('Login_PasswordLabel')}
              placeholder={translate('Login_PasswordPlaceholder')}
              onChange={(event) => setPassword(event.target.value)}
              error={error != null}
            />
            <BodyText className={styles.mandatoryField}>
              {translate('Login_MandatoryField')}
            </BodyText>
          </div>
          <div className={styles.buttonCntrRow}>
            <div className={styles.buttonCntr}>
              <RoundButtonLoadable
                disabled={!emailValidation.valid}
                loading={isLoading}
                onClick={onClickedButton}
                id="login_loginform_btn"
                type="submit"
              >
                {translate('Login_SignInButton')}
              </RoundButtonLoadable>
            </div>
            <div
              onClick={onForgotPasswordClicked}
              className={styles.forgotPasswordCntr}
            >
              <p className={styles.forgotPassword}>
                {translate('Login_ForgotPassword')}
              </p>
            </div>
          </div>
        </form>
        <div className={styles.rightCntr}>
          <LoginHelpBlock
            title={translate('Login_DontHaveAccount')}
            desc={translate('Login_RegisterNewAccount')}
            buttonText={translate('General_SignUp')}
            onClick={onSignupClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
