import { useState, useEffect, useCallback, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import clsx from 'clsx';
import { Header3, InfoText } from 'components/UI/Brand/text';
import inputValidator from 'utils/inputValidator';
import TextInput from 'components/UI/TextInput/TextInput';
import translate from 'translate';
import { fetchValidLeadValues } from 'store/actions';
import { createFailedMessage } from 'store/actions/headerMessage';
import localStorageKeys from 'utils/localStorageKeys';
import { getSupportEmail } from 'utils/supportInfo';
import PhoneInput from 'components/UI/PhoneInput/PhoneInput';
import { IRootState } from 'store/types/root';
import Dropdown from 'components/UI/Select/Dropdown';
import debounce from 'utils/debounce';
import { DEBOUNCE_TIME } from 'utils/constants';
import { CloseModalIcon, RoundButtonLoadable } from 'components';
import { useAppDispatch } from 'hooks/storeHooks';
import styles from './styles.module.scss';

interface Input {
  value: string;
  valid: boolean;
  touched: boolean;
}

type InputName =
  | 'agencyName'
  | 'agentFirstName'
  | 'agentLastName'
  | 'agencyEmail'
  | 'zip'
  | 'country'
  | 'phoneNumber'
  | 'address';

interface IEmailUsModalProps {
  onClose: () => void;
  setRequestSent: () => void;
}

const EmailUsModal = ({ onClose, setRequestSent }: IEmailUsModalProps) => {
  const dispatch = useAppDispatch();
  const [input, setInput] = useState<Record<InputName, Input>>({
    agencyName: { value: '', valid: false, touched: false },
    agentFirstName: { value: '', valid: false, touched: false },
    agentLastName: { value: '', valid: false, touched: false },
    agencyEmail: { value: '', valid: false, touched: false },
    address: { value: '', valid: false, touched: false },
    zip: { value: '', valid: false, touched: false },
    country: { value: '', valid: false, touched: false },
    phoneNumber: { value: '', valid: false, touched: false },
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoadingBarVisible, setIsLoadingBarVisible] = useState(false);
  const featureFlags = useSelector((state: IRootState) => state.feature.flags);
  const { leadValidValues: leadValues, email: agentEmail } = useSelector(
    (state: IRootState) => state.onboarding
  );

  useEffect(() => {
    if (!leadValues) {
      dispatch(fetchValidLeadValues());
    }
  }, [leadValues]);

  const validateForm = useCallback(
    debounce((inputs: Record<InputName, Input>) => {
      const validForm = Object.entries(inputs).every(
        ([, { valid, value }]) => valid && !!value
      );
      setIsFormValid(validForm);
    }, DEBOUNCE_TIME),
    []
  );

  const handleInputChange = (name: InputName, value: string) => {
    const newInput = {
      ...input,
      [name]: {
        valid: inputValidator(name, value),
        value,
        touched: true,
      },
    };

    setInput(newInput);
  };

  const getCountries = () => {
    if (!featureFlags) {
      return [];
    }

    if (!leadValues || !leadValues.fields) {
      return [];
    }

    const countryField = leadValues.fields.find(
      (field) => field.name === 'Country'
    );
    if (!countryField) {
      return [];
    }
    return countryField.picklistValues;
  };

  const onSendRequestClick = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let supportEmail = localStorage.getItem(localStorageKeys.supportEmail);
    if (!supportEmail) {
      supportEmail = await getSupportEmail();
    }

    const requestBody: Partial<Record<InputName, string>> = Object.entries(
      input
    ).reduce(
      (body, [name, entry]) => ({
        ...body,
        [name]: entry.value,
      }),
      {}
    );

    const emailToSend = {
      email: supportEmail,
      agencyIdRequestBody: { ...requestBody, agentEmail },
    };

    setIsLoadingBarVisible(true);
    await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}User/agency/agencyidrequest`,
      emailToSend
    )
      .then(() => {
        setIsLoadingBarVisible(false);
        onClose();
        setRequestSent();
      })
      .catch(() => {
        setIsLoadingBarVisible(false);
        onClose();
        dispatch(createFailedMessage(translate('EmailUsModal_SendingFailed')));
      });

    return false;
  };

  useEffect(() => {
    validateForm(input);
  }, [input]);

  return (
    <form onSubmit={onSendRequestClick}>
      <div className={styles.container}>
        <Header3 as="h1" className={styles.title}>
          {translate('EmailUsModal_Title')}
        </Header3>
        <CloseModalIcon onClose={onClose} />
      </div>
      <InfoText>{translate('EmailUsModal_SubTitle')}</InfoText>
      <div className={styles.formContainer}>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <TextInput
              name="agencyName"
              placeholder={''}
              label={translate('EmailUsModal_AgencyName_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.agencyName.value}
              helperText={
                !input.agencyName.valid && input.agencyName.touched
                  ? translate('EmailUsModal_AgencyName_ValidationText')
                  : null
              }
              error={!input.agencyName.valid && input.agencyName.touched}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              name="agentFirstName"
              placeholder={''}
              label={translate('EmailUsModal_AgentFirstName_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.agentFirstName.value}
              helperText={
                !input.agentFirstName.valid && input.agentFirstName.touched
                  ? translate('EmailUsModal_AgentFirstName_ValidationText')
                  : null
              }
              error={
                !input.agentFirstName.valid && input.agentFirstName.touched
              }
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <TextInput
              name="agentLastName"
              placeholder={''}
              label={translate('EmailUsModal_AgentLastName_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.agentLastName.value}
              helperText={
                !input.agentLastName.valid && input.agentLastName.touched
                  ? translate('EmailUsModal_AgentLastName_ValidationText')
                  : null
              }
              error={!input.agentLastName.valid && input.agentLastName.touched}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              name="agencyEmail"
              placeholder={''}
              label={translate('EmailUsModal_Email_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.agencyEmail.value}
              helperText={
                !input.agencyEmail.valid && input.agencyEmail.touched
                  ? translate('EmailUsModal_Email_ValidationText')
                  : null
              }
              error={!input.agencyEmail.valid && input.agencyEmail.touched}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <TextInput
              name="address"
              placeholder={''}
              label={translate('EmailUsModal_Address_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.address.value}
              helperText={
                !input.address.valid && input.address.touched
                  ? translate('EmailUsModal_Address_ValidationText')
                  : null
              }
              error={!input.address.valid && input.address.touched}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              name="zip"
              placeholder={''}
              label={translate('EmailUsModal_Zip_Label')}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              value={input.zip.value}
              helperText={
                !input.zip.valid && input.zip.touched
                  ? translate('EmailUsModal_Zip_ValidationText')
                  : null
              }
              error={!input.zip.valid && input.zip.touched}
            />
          </div>
        </div>
        <div className={clsx(styles.inputRow, styles.countrySelect)}>
          <div className={styles.inputContainer}>
            <Dropdown
              placeholder={translate('EmailUsModal_Country_Placeholder')}
              label={translate('EmailUsModal_Country_Label')}
              name="country"
              id="emailus_country"
              options={getCountries()}
              value={input.country.value}
              onChange={({ target: { name, value } }) =>
                handleInputChange(name as InputName, value)
              }
              helperText={
                !input.country.valid
                  ? translate('EmailUsModal_Country_ValidationText')
                  : null
              }
              error={!input.country.valid && input.country.touched}
            />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <PhoneInput
              id="agency_details_phone_input"
              isRequired
              label={translate('EmailUsModal_PhoneNumber_Label')}
              onChange={(phoneNumber) => {
                setInput((prevInputs) => ({
                  ...prevInputs,
                  phoneNumber: {
                    ...prevInputs.phoneNumber,
                    value: phoneNumber,
                    valid: inputValidator('phoneNumber', phoneNumber),
                  },
                }));
              }}
              value={input.phoneNumber.value}
              error={!input.phoneNumber.valid && input.phoneNumber.touched}
              helperText={
                !input.phoneNumber.valid && input.phoneNumber.touched
                  ? translate('EmailUsModal_PhoneNumber_ValidationText')
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <RoundButtonLoadable
          type="submit"
          disabled={!isFormValid}
          loading={isLoadingBarVisible}
        >
          {translate('EmailUsModal_SendRequestButton_Text')}
        </RoundButtonLoadable>
      </div>
    </form>
  );
};

export default EmailUsModal;
