import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BodyText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { executeScroll } from 'utils/executeScroll';
import AnchorLink from 'components/UI/Links/AnchorLink';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import translate from 'translate';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { media } from 'style/sizes';
import BackToButton from '../UI/Buttons/BackButton/BackToButton';
import styles from './styles.module.scss';

export interface IMoreInfoLink {
  linkId: string;
  linkTitle: string;
  linkPath: string;
  shouldOpenInNewTab: boolean;
}

interface IMoreInformationBarProps {
  isSticky?: boolean;
  isCoastal: boolean;
  listOfLinks: IMoreInfoLink[];
  isBookingFunnel?: boolean;
  voyageId?: string;
  destinationId?: string;
  tourType?: string;
}

const MoreInformationBar = ({
  isSticky,
  isCoastal,
  listOfLinks,
  isBookingFunnel = false,
  voyageId,
  destinationId,
  tourType,
}: IMoreInformationBarProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(null);
  const [isScrolling, setScrolling] = useState<boolean>(null);
  const [activeItem, setActiveItem] = useState<string>('');

  const isLaptop = useMediaQuery(media.minLaptop);

  const { hash } = useLocation();
  const locationOnSite = hash.split('#')[1];

  const stickyElementsHeight = 175;

  const getElementOffset = (element: HTMLElement) => {
    const elementRect = element?.getBoundingClientRect();
    return elementRect?.top;
  };

  // Listens for scroll event and the offset between element and viewport to setScrolling
  const onScrollListener = () => {
    const moreInformationBar = document.getElementById('more_information_bar');
    const heightToShowElements = getElementOffset(moreInformationBar);
    const windowScrollHeight =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (windowScrollHeight < heightToShowElements + stickyElementsHeight) {
      setScrolling(false);
    } else {
      setScrolling(true);
    }
  };

  useEffect(() => {
    if (isSticky) {
      window.addEventListener('scroll', onScrollListener);
      return () => window.removeEventListener('scroll', onScrollListener);
    }
    return undefined;
  }, []);

  // Listens for anchor points and sets active styling when they enter viewport
  const anchorObserver = new window.IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          listOfLinks.forEach(({ linkId }) => {
            if (linkId === entry.target.id) {
              setActiveItem(linkId);
              window.history.replaceState({}, linkId, `#${linkId}`);
            }
          });
        }
      });
    },
    { threshold: [isLaptop ? 0.1 : 0.25] }
  );

  useEffect(() => {
    if (isSticky) {
      anchorObserver.disconnect();
      listOfLinks?.forEach(({ linkId }) => {
        const element = document.getElementById(linkId);
        if (element) anchorObserver.observe(element);
      });
      return () => {
        anchorObserver.disconnect();
      };
    }
    return undefined;
  }, []);

  // Sets the active styling to each link in menu depending on path
  useEffect(() => {
    if (hash) {
      setActiveItem(locationOnSite);
      setTimeout(() => {
        executeScroll(locationOnSite, stickyElementsHeight);
      }, 250);
    }
  }, [locationOnSite]);

  const canLinkHaveActiveStyling = (linkId: string, index: number) => {
    if (isSticky) {
      if (activeItem === linkId || (activeItem === '' && !hash && index === 0))
        return true;
    }
    return false;
  };

  const listOfMoreInformationLinks = (
    <ul
      className={styles.listContainer}
      aria-labelledby={translate('General_MoreInformation')}
    >
      {!isSticky && isLaptop && (
        <div
          className={clsx(styles.buttonContainer, {
            [styles.isCoastalButtonContainer]: isCoastal,
          })}
        >
          <BackToButton isCoastal={isCoastal} />
        </div>
      )}
      {listOfLinks?.map((props: IMoreInfoLink, index) => {
        const { linkPath, linkTitle, linkId, shouldOpenInNewTab } = props;
        return (
          linkPath && (
            <li
              key={`${linkPath}-${index + 1}`}
              className={clsx(styles.listItem, {
                [styles.isBookingFunnelListItem]: isBookingFunnel,
                [styles.isStickyListItem]: isSticky && !isCoastal,
                [styles.isStickyCoastalListItem]: isSticky && isCoastal,
                [styles.activeListItem]: canLinkHaveActiveStyling(
                  linkId,
                  index
                ),
                [styles.isBookingFunnelActiveListItem]:
                  isBookingFunnel && canLinkHaveActiveStyling(linkId, index),
              })}
              onClick={() => !isLaptop && setIsAccordionOpen(false)}
            >
              <AnchorLink
                to={linkPath}
                anchorId={linkId}
                className={clsx(styles.linkText, {
                  [styles.isLinkTextSticky]: isSticky,
                })}
                title={linkTitle}
                shouldOpenInNewTab={shouldOpenInNewTab}
                iconColor={colors.shared.white}
                stickyElementsHeight={stickyElementsHeight}
              />
            </li>
          )
        );
      })}
      {isSticky && isLaptop && isScrolling && (
        <button
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            window.history.replaceState({}, '#', ' ');
          }}
          className={clsx(styles.backToTopButton, {
            [styles.isCoastalBackToTopButton]: isCoastal,
          })}
          aria-label={translate('General_BackToTop')}
        >
          <Icon iconType="arrowUpLong" height={17} width={17} />
        </button>
      )}

      {!isBookingFunnel && isLaptop && (
        <div style={{ marginLeft: 'auto' }}>
          <PrimaryButton
            to={
              isCoastal
                ? `/book/coastal?voyageIds=${voyageId}&coastalType=${tourType}`
                : `/book/expedition?destination=${destinationId}`
            }
            className={clsx(styles.searchLink, {
              [styles.invertedBlackButton]: !isCoastal,
            })}
          >
            {translate('Voyage_Itinerary_GoToSearch_Button')}
            <Icon iconType="arrowRightShort" />
          </PrimaryButton>
        </div>
      )}
    </ul>
  );

  return (
    <div
      className={clsx(styles.moreInformationBar, {
        [styles.sticky]: isSticky,
        [styles.isCoastal]: isCoastal,
      })}
      id="more_information_bar"
    >
      <div
        className={clsx(styles.container, {
          [styles.isStickyContainer]: isSticky,
        })}
      >
        {isLaptop ? (
          listOfMoreInformationLinks
        ) : (
          <>
            {!isBookingFunnel && (
              <div style={{ marginLeft: 'auto' }}>
                <PrimaryButton
                  to={
                    isCoastal
                      ? `/book/coastal?voyageIds=${voyageId}&coastalType=${tourType}`
                      : `/book?destinationId=${destinationId}`
                  }
                  className={clsx(styles.searchLink, {
                    [styles.invertedBlackButton]: !isCoastal,
                  })}
                >
                  {translate('Voyage_Itinerary_GoToSearch_Button')}
                  <Icon iconType="arrowRightShort" />
                </PrimaryButton>
              </div>
            )}
            <h3>
              <button
                className={styles.accordionButton}
                aria-expanded={isAccordionOpen}
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
              >
                <BodyText>
                  {isAccordionOpen
                    ? translate('General_SeeLessInformation')
                    : translate('General_SeeMoreInformation')}
                </BodyText>
                <Icon iconType="arrowDown" width={15} />
              </button>
            </h3>
            <div hidden={!isAccordionOpen}>{listOfMoreInformationLinks}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default MoreInformationBar;
