import { SVGProps } from 'react';

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M15.55 31.1c8.588 0 15.551-6.963 15.551-15.55C31.101 6.96 24.138 0 15.551 0 6.96 0 0 6.961 0 15.55 0 24.136 6.961 31.1 15.55 31.1"
      />
      <path
        fill="#232120"
        d="M23.325 18.33v-5.56s0-2.68-2.68-2.68H10.454s-2.679 0-2.679 2.68v5.56s0 2.68 2.679 2.68h10.191s2.68 0 2.68-2.68m-4.756-2.773-5.09 2.99v-5.98l5.09 2.99"
      />
    </g>
  </svg>
);

export default YoutubeIcon;
