import { TIcon } from '../Icon.type';
import {
  AccessibilityIcon,
  AreaIcon,
  ArrowDownIcon,
  ArrowDownLongIcon,
  ArrowLeftIcon,
  ArrowLeftLongIcon,
  ArrowLeftShortIcon,
  ArrowRightIcon,
  ArrowRightLongIcon,
  ArrowRightShortIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ArrowUpLongIcon,
  ArticleIcon,
  BalconyIcon,
  BathroomIcon,
  BearIcon,
  BinocularIcon,
  BrochureIcon,
  CalendarIcon,
  CarIcon,
  CheckmarkIcon,
  CheckPaperIcon,
  CircleInformationIcon,
  CircleMoneyIcon,
  CirclePlayIcon,
  CircleQuestionIcon,
  CircleWithCheckmarkIcon,
  CircleWithCheckmarkSmallIcon,
  CircleWithCrossIcon,
  CircleWithExclamationIcon,
  CircleWithExclamationSmallIcon,
  ClockIcon,
  CreditcardIcon,
  CrossIcon,
  DimensionsIcon,
  DoubleBedIcon,
  DownloadIcon,
  EarthIcon,
  EyeIcon,
  EyeLessIcon,
  FacebookIcon,
  FilledCircleWithMinus,
  FilledCircleWithMinusInverted,
  FilledCircleWithPlus,
  FilledCircleWithPlusInverted,
  FlagIcon,
  FloaterIcon,
  InstagramIcon,
  LocationIcon,
  MailIcon,
  MenuIcon,
  MessageIcon,
  MinusIcon,
  NewTabIcon,
  NoWindowIcon,
  PaperIcon,
  PaymentIcon,
  PhoneIcon,
  PipeIcon,
  PlusIcon,
  SandGlassIcon,
  SearchAlertIcon,
  SearchIcon,
  ShareIcon,
  ShipFrontIcon,
  SingleBedIcon,
  SnowflakeIcon,
  SofaIcon,
  SpringIcon,
  SummerIcon,
  TrashIcon,
  TvIcon,
  TwitterIcon,
  UserIcon,
  WatchIcon,
  WheelchairSmallIcon,
  WifiIcon,
  WindowIcon,
  YoutubeIcon,
  BusIcon,
  RailIcon,
  FlightIcon,
  CircleWithCrossSmallIcon,
  ExclamationInTriangleSmallIcon,
} from '../IconComponents';

export const getIcon = (iconType: TIcon) => {
  switch (iconType) {
    case 'accessibility':
      return AccessibilityIcon;
    case 'area':
      return AreaIcon;
    case 'arrowDown':
      return ArrowDownIcon;
    case 'arrowDownLong':
      return ArrowDownLongIcon;
    case 'arrowLeft':
      return ArrowLeftIcon;
    case 'arrowLeftShort':
      return ArrowLeftShortIcon;
    case 'arrowLeftLong':
      return ArrowLeftLongIcon;
    case 'arrowRight':
      return ArrowRightIcon;
    case 'arrowRightShort':
      return ArrowRightShortIcon;
    case 'arrowRightLong':
      return ArrowRightLongIcon;
    case 'arrowUp':
      return ArrowUpIcon;
    case 'arrowUpLong':
      return ArrowUpLongIcon;
    case 'arrowUpDown':
      return ArrowUpDownIcon;
    case 'article':
      return ArticleIcon;
    case 'balcony':
      return BalconyIcon;
    case 'bathroom':
      return BathroomIcon;
    case 'bear':
      return BearIcon;
    case 'binocular':
      return BinocularIcon;
    case 'brochure':
      return BrochureIcon;
    case 'circlePlay':
      return CirclePlayIcon;
    case 'calendar':
      return CalendarIcon;
    case 'checkmark':
    case 'check':
      return CheckmarkIcon;
    case 'checkPaper':
      return CheckPaperIcon;
    case 'circleMoney':
      return CircleMoneyIcon;
    case 'circleWithCheckmark':
      return CircleWithCheckmarkIcon;
    case 'circleWithCheckmarkSmall':
      return CircleWithCheckmarkSmallIcon;
    case 'circleWithCross':
      return CircleWithCrossIcon;
    case 'circleWithCrossSmall':
      return CircleWithCrossSmallIcon;
    case 'circleWithExclamation':
      return CircleWithExclamationIcon;
    case 'clock':
      return ClockIcon;
    case 'download':
      return DownloadIcon;
    case 'location':
      return LocationIcon;
    case 'circleWithExclamationSmall':
      return CircleWithExclamationSmallIcon;
    case 'creditCard':
      return CreditcardIcon;
    case 'payment':
      return PaymentIcon;
    case 'earth':
      return EarthIcon;
    case 'floater':
      return FloaterIcon;
    case 'sandGlass':
      return SandGlassIcon;
    case 'cross':
      return CrossIcon;
    case 'minus':
      return MinusIcon;
    case 'flag':
      return FlagIcon;
    case 'plus':
      return PlusIcon;
    case 'shipFront':
    case 'ship':
    case 'ferry':
      return ShipFrontIcon;
    case 'wheelchairSmall':
      return WheelchairSmallIcon;
    case 'search':
      return SearchIcon;
    case 'circleInformation':
      return CircleInformationIcon;
    case 'doubleBed':
      return DoubleBedIcon;
    case 'message':
      return MessageIcon;
    case 'sofa':
      return SofaIcon;
    case 'tv':
      return TvIcon;
    case 'window':
      return WindowIcon;
    case 'dimensions':
      return DimensionsIcon;
    case 'menu':
      return MenuIcon;
    case 'newTab':
      return NewTabIcon;
    case 'noWindow':
      return NoWindowIcon;
    case 'user':
      return UserIcon;
    case 'searchAlert':
      return SearchAlertIcon;
    case 'pipe':
      return PipeIcon;
    case 'facebook':
    case 'facebook hx':
      return FacebookIcon;
    case 'phone':
      return PhoneIcon;
    case 'instagram':
    case 'instagram hx':
      return InstagramIcon;
    case 'twitter':
      return TwitterIcon;
    case 'youtube':
    case 'youtube hx':
      return YoutubeIcon;
    case 'eyeLess':
      return EyeLessIcon;
    case 'eye':
      return EyeIcon;
    case 'car':
      return CarIcon;
    case 'circleQuestion':
      return CircleQuestionIcon;
    case 'watch':
      return WatchIcon;
    case 'share':
      return ShareIcon;
    case 'singleBed':
      return SingleBedIcon;
    case 'snowflake':
      return SnowflakeIcon;
    case 'spring':
      return SpringIcon;
    case 'summer':
      return SummerIcon;
    case 'paper':
      return PaperIcon;
    case 'wifi':
      return WifiIcon;
    case 'filledCircleWithMinus':
      return FilledCircleWithMinus;
    case 'filledCircleWithMinusInverted':
      return FilledCircleWithMinusInverted;
    case 'filledCircleWithPlus':
      return FilledCircleWithPlus;
    case 'filledCircleWithPlusInverted':
      return FilledCircleWithPlusInverted;
    case 'trash':
      return TrashIcon;
    case 'flight':
      return FlightIcon;
    case 'rail':
      return RailIcon;
    case 'transfer':
    case 'bus':
      return BusIcon;
    case 'mail':
      return MailIcon;
    case 'triangleWithExclamationSmall':
      return ExclamationInTriangleSmallIcon;
    default:
      return null;
  }
};
