import { handleActions } from 'redux-actions';
import * as actions from '../actions/summary';

const initialState = {
  summaryData: null,
  error: null,
  loading: false,
  sendingSummary: false,
};

const reducer = handleActions(
  {
    [actions.fetchSummaryStart]: (state) => ({
      ...state,
      loading: true,
    }),
    [actions.fetchSummarySuccess]: (state, action) => ({
      ...state,
      summaryData: action.payload.summaryData,
      error: null,
      loading: false,
    }),
    [actions.fetchSummaryFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [actions.shareSummaryStart]: (state) => ({
      ...state,
      sendingSummary: true,
    }),
    [actions.shareSummarySuccess]: (state) => ({
      ...state,
      sendingSummary: false,
      error: null,
    }),
    [actions.shareSummaryFailed]: (state, action) => ({
      ...state,
      sendingSummary: false,
      error: action.payload.error,
    }),
    [actions.resetSummary]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
