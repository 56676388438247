import { format } from 'date-fns';
import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { FocusOn } from 'react-focus-on';
import translate from 'translate';
import CalendarFlexible from 'components/UI/Calendar/CalendarFlexible';
import { useAppSelector } from 'hooks/storeHooks';
import { getLanguage } from 'utils/getLanguage';
import { DatePicker } from 'components/DatePicker/DatePicker';
import languageKeys from 'utils/languageKeys';
import { getCalendarLocale } from 'utils/dates';
import CalendarInput from './CalendarInput';
import Dropdown from '../Select/DropdownComponent';
import styles from './styles.module.scss';

type FocusedInputShape = 'startDate' | 'endDate';

interface ICalendar {
  id: string;
  isFlexible?: boolean;
  onDateChange: (date: Date) => void;
  monthsAvailableForDestinationType?: string[];
  selectedUnixPeriods?: number[];
  setSelectedUnixPeriods?: (unixPeriods: number[]) => void;
  value: Date;
  isLoading?: boolean;
  isDatesDropdown?: boolean;
}

const Calendar = ({
  id,
  isFlexible = false,
  onDateChange,
  selectedUnixPeriods,
  setSelectedUnixPeriods,
  value,
  monthsAvailableForDestinationType = [],
  isLoading,
  isDatesDropdown,
}: ICalendar) => {
  const language = getLanguage();
  const locale = getCalendarLocale(language);
  const { packageDates } = useAppSelector((state) => state.search);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(true);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    'startDate'
  );
  const calendarControlRef = useRef(null);
  const uniqueSpecificDates = new Set(packageDates);

  const dateFormat =
    language === languageKeys.EN_AMERICAS ? 'MMMM dd, yyyy' : 'dd MMMM, yyyy';

  const specificDatesOptions = [...uniqueSpecificDates]?.map((date) => ({
    value: date,
    description: format(date, dateFormat, { locale }) as string,
  }));

  useEffect(() => {
    if (!focusedInput) {
      setIsOpen(false);
      setFocusedInput('startDate');
    }
    if (!isInputFocused) {
      setIsOpen(false);
      setIsInputFocused(true);
    }
  }, [isInputFocused, focusedInput]);

  const handleDateChange = (date: Date) => {
    onDateChange(date);
  };

  const handleSpecificDateChange = (date: string) => {
    onDateChange(new Date(date));
  };

  return (
    <div className={styles.calendarContainer}>
      {!isDatesDropdown && !isFlexible && (
        <>
          <DatePicker
            selected={value}
            onChange={(date) => date && handleDateChange(date)}
            language={language}
            id={id}
            dateFormat={dateFormat}
          />
        </>
      )}
      {!isDatesDropdown && isFlexible && (
        <>
          <CalendarInput
            id={id}
            selectedUnixPeriods={selectedUnixPeriods}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            value={value}
            focusedInput={focusedInput}
            isLoading={isLoading}
          />
          {isOpen && (
            <FocusOn
              shards={[calendarControlRef]}
              onClickOutside={() => setIsOpen(false)}
              onEscapeKey={() => setIsOpen(false)}
            >
              <div className={styles.content}>
                <CalendarFlexible
                  selectedUnixPeriods={selectedUnixPeriods}
                  setSelectedUnixPeriods={setSelectedUnixPeriods}
                  monthsAvailableForDestinationType={
                    monthsAvailableForDestinationType
                  }
                />
                <div className={styles.buttonContainer}>
                  <SecondaryButton
                    className={styles.doneButton}
                    onClick={() => setIsOpen(false)}
                  >
                    {translate('General_Done')}
                  </SecondaryButton>
                </div>
              </div>
            </FocusOn>
          )}
        </>
      )}
      {!!isDatesDropdown && (
        <div className={styles.specificDatesDropdown}>
          <Dropdown
            id={'specific-dates'}
            name="specific dates"
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handleSpecificDateChange(event.currentTarget.value);
            }}
            placeholder={
              isLoading
                ? translate('General_LoadingData')
                : specificDatesOptions?.[0]?.description
            }
            options={specificDatesOptions}
            value={format(value, "yyyy-MM-dd'T'HH:mm:ss")}
            disabled={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default Calendar;
