import clsx from 'clsx';
import { BodyText, InfoText } from 'components/UI/Brand/text';
import { StrikeThroughPrice } from 'components/UI/StrikeThroughPrice';
import translate from 'translate';
import { getPriceFormat, getMinimumPrice } from 'utils/search';
import { ITableSearchResult } from 'interfaces/ITableSearchResult';
import { useAppSelector } from 'hooks/storeHooks';
import { AlertBox, Icon } from 'components';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import { COASTAL_OPTIONS } from 'utils/constants';
import ShipAndDepartureInformation from '../ShipAndDepartureInformation';
import { getMaxPaxData } from '../../utils/getMaxPaxData';
import styles from './styles.module.scss';

const DepartureInformation = ({
  arrivalDate,
  departureDate,
  index,
  isCoastal,
  onPriceClicked,
  voyageId,
  prices,
  promotionList,
  shipDetail,
  isPortToPortVoyage,
  hasSearchedWithDeckSpace,
  isViaGeiranger,
  isViaHjorundfjorden,
  hasVehicleAvailability,
  travelSuggestionCode,
}: ITableSearchResult) => {
  const displayP2PResult =
    isPortToPortVoyage && getMinimumPrice(prices).price !== Infinity;
  const {
    params: { cabins },
    coastalVoyage,
  } = useAppSelector((state) => state.search);
  const { cabinCategories } = shipDetail;
  const { hasMaxPaxNotification, maxOccupancy } = getMaxPaxData({
    cabins,
    cabinCategories,
    hasSearchedWithDeckSpace,
    isCoastal,
  });
  const isIconicVoyage = [
    COASTAL_OPTIONS.PACKAGE_VOYAGE,
    COASTAL_OPTIONS.NORTH_CAPE_EXPRESS,
    COASTAL_OPTIONS.SVALBARD_EXPRESS,
  ].includes(coastalVoyage);

  const isCabinSearchAndNoCabinAvailability =
    cabinCategories.length === 0 && !hasSearchedWithDeckSpace;

  const isFjordVisit =
    isPortToPortVoyage && (isViaGeiranger || isViaHjorundfjorden);

  const isButtonDisabled =
    getPriceFormat(getMinimumPrice(prices).price) === 'Sold out' ||
    isCabinSearchAndNoCabinAvailability ||
    !hasVehicleAvailability ||
    hasMaxPaxNotification;

  const canShowWarningBanner =
    (isCabinSearchAndNoCabinAvailability ||
      !hasVehicleAvailability ||
      hasMaxPaxNotification) &&
    displayP2PResult;

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.root, {
          [styles.displayP2PResult]: displayP2PResult,
        })}
      >
        <div className={styles.departureInfoContainer}>
          <ShipAndDepartureInformation
            departureDate={departureDate}
            arrivalDate={arrivalDate}
            promotionList={promotionList}
            index={index}
            shipDetail={shipDetail}
            isCoastal={isCoastal}
            travelSuggestionCode={travelSuggestionCode}
            isIconicVoyage={isIconicVoyage}
          />
        </div>
        {displayP2PResult && (
          <div className={styles.priceAndButtonContainer}>
            <PrimaryButton
              disabled={isButtonDisabled}
              onClick={() => onPriceClicked(voyageId)}
            >
              {translate('SearchResultItemRow_SelectDeparture')}
            </PrimaryButton>
            {!isCabinSearchAndNoCabinAvailability && hasVehicleAvailability && (
              <div className={styles.priceContainer}>
                <div>
                  <StrikeThroughPrice
                    price={getMinimumPrice(prices).price}
                    strikeThroughPrice={
                      getMinimumPrice(prices).strikeThroughPrice
                    }
                  />
                  <BodyText className={styles.price}>
                    {getPriceFormat(getMinimumPrice(prices).price)}
                  </BodyText>
                  <InfoText className={styles.perPerson}>
                    {translate('General_PerPerson')}
                  </InfoText>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {canShowWarningBanner && (
        <AlertBox type="warning" className={styles.infoBoxWarning}>
          {isCabinSearchAndNoCabinAvailability &&
            translate('Search_Alert_NoCabinSpace')}
          {hasMaxPaxNotification &&
            translate(
              'SearchResultItemRow_CabinSearchOverCapacity',
              maxOccupancy
            )}
          {!hasVehicleAvailability && translate('Search_Alert_NoVehicleSpace')}
        </AlertBox>
      )}
      {isFjordVisit && displayP2PResult && (
        <AlertBox
          className={styles.infoBox}
          type="info"
          OverrideIcon={<Icon iconType="circleWithExclamation" />}
        >
          {translate(
            isViaGeiranger
              ? 'Search_Alert_Geiranger'
              : 'Search_Alert_Hjorundfjorden'
          )}
        </AlertBox>
      )}
    </div>
  );
};

export default DepartureInformation;
