interface ICustomImage {
  alt?: string;
  className?: string;
  fit?: string;
  height?: number;
  isPresentation?: boolean;
  png?: boolean;
  src: string;
  width?: number;
  dataTestid?: string;
}

const CustomImage = ({
  alt,
  className,
  fit,
  height,
  isPresentation,
  png,
  src,
  width,
  dataTestid,
}: ICustomImage) => {
  let isBase64 = false;
  let extendedSrc = '';

  if (!src) {
    return null;
  }
  let link = `${src}`;
  if (!isBase64 && src.startsWith('//')) {
    link = `https://${link.replace(/^\/+/, '')}`;
  } else if (!isBase64 && !src.startsWith('http')) {
    link = `https://${link.replace(/^\/+/, '')}`;
  }

  if (!src.includes('hurtigruten')) {
    if (!src.endsWith('?')) {
      link += '?';
    }
    link += `w=${width || 1200}&h=${height || 1200}&fit=${fit || 'thumb'}&fm=${
      png ? 'png' : 'jpg&fl=progressive'
    }&q=80`;
  }
  extendedSrc = link;
  isBase64 = src.indexOf('data:image') > -1 && src.indexOf('base64') > -1;

  return (
    <img
      src={extendedSrc}
      className={className}
      alt={alt || undefined}
      role={isPresentation ? 'presentation' : undefined}
      data-testid={dataTestid}
    />
  );
};

export default CustomImage;
