import Axios from 'axios';
import { IContactInfo } from '../interfaces/IHome';
import { getLocaleByRegion } from './getLocale';
import localStorageKeys from './localStorageKeys';
import { Region } from './regions';

export const DEFAULT_SUPPORT_EMAIL = 'b2b.support@hurtigruten.com';

export const storeSupportInfo = (supportInfo: IContactInfo) => {
  localStorage.setItem(localStorageKeys.supportEmail, supportInfo.email[0]);
  localStorage.setItem(localStorageKeys.supportPhone, supportInfo.phone[0]);
  localStorage.setItem(
    localStorageKeys.supportAddress,
    supportInfo.mailingAddress[0]
  );
};

export const setSupportInfo = async (region: Region = null) => {
  const regionParameter = region ? `${getLocaleByRegion(region)}` : '';
  const supportInfo = (
    await Axios.get<IContactInfo>(
      `${process.env.REACT_APP_API_BASE_URL}contact/${regionParameter}`
    )
  ).data;

  storeSupportInfo(supportInfo);
  return supportInfo.email[0];
};

// depricated, use useSupportEmail hook
export const getSupportEmail = async (region: Region = null) => {
  const supportEmail = localStorage.getItem(localStorageKeys.supportEmail);
  if (!supportEmail) {
    return setSupportInfo(region);
  }
  return supportEmail;
};

export const getSupportEmailSync = (): string | null => {
  const supportEmail = localStorage.getItem(localStorageKeys.supportEmail);
  if (!supportEmail) {
    return null;
  }
  return supportEmail;
};
