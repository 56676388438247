import React from 'react';
import { BodyText } from 'components/UI/Brand/text';
import translate from 'translate';
import styles from './styles.module.scss';

const { REACT_APP_COMPANY } = process.env;

const companyLink =
  REACT_APP_COMPANY === 'HX'
    ? {
        text: translate('Split_Header_Visit_Hurtigruten'),
        url: 'https://agentportal.hurtigruten.com',
      }
    : {
        text: translate('Split_Header_Visit_HX'),
        url: 'https://agentportal.travelhx.com',
      };

const SplitHeader: React.FC = () => (
  <div className={styles.splitHeader}>
    <div className={styles.contentContainer}>
      <div className={styles.imgContainer}>
        {REACT_APP_COMPANY !== 'HX' ? (
          <BodyText>{translate('Hurtigruten_is_HX')}</BodyText>
        ) : (
          <BodyText>{translate('Split_Looking_for_Hurtigruten')}</BodyText>
        )}
      </div>
      <a
        href={companyLink.url}
        className={styles.companyLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {companyLink.text}
      </a>
    </div>
  </div>
);

export default SplitHeader;
