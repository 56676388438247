import { format } from 'date-fns';
import clsx from 'clsx';
import { getTimeFormat } from 'utils/multilanguage-support';
import { IExcursion } from 'interfaces/package/IExcursion';
import { IExtraItemsData } from 'interfaces/IExtras';
import translate from 'translate';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface IExtraIconList {
  data: IExtraItemsData;
  isAdded: boolean;
}

const IconList = ({ data, isAdded }: IExtraIconList) => {
  const startTimeText = (data.extra as IExcursion).startDateTime
    ? format(
        new Date((data.extra as IExcursion).startDateTime),
        getTimeFormat()
      )
    : null;

  return (
    <ul className={styles.iconList}>
      {startTimeText && (
        <li
          className={clsx(styles.iconItem, {
            [styles.isAdded]: isAdded,
          })}
        >
          <div className={styles.icon}>
            <Icon
              iconType="clock"
              color={isAdded ? colors.shared.white : colors.shared.black}
              width={20}
              height={20}
            />
          </div>
          {startTimeText}
        </li>
      )}
      {(data.extra as IExcursion).duration && (
        <li
          className={clsx(styles.iconItem, {
            [styles.isAdded]: isAdded,
          })}
        >
          <div className={styles.icon}>
            <Icon
              iconType="sandGlass"
              color={isAdded ? colors.shared.white : colors.shared.black}
              width={20}
              height={20}
            />
          </div>
          {(data.extra as IExcursion).duration}
        </li>
      )}
      {(data.extra as IExcursion).startPort && (
        <li
          className={clsx(styles.iconItem, {
            [styles.isAdded]: isAdded,
          })}
        >
          <div className={styles.icon}>
            <Icon
              iconType="location"
              color={isAdded ? colors.shared.white : colors.shared.black}
              width={20}
              height={20}
            />
          </div>
          {(data.extra as IExcursion).startPort}
        </li>
      )}
      {(data.extra as IExcursion).difficulty && (
        <li
          className={clsx(styles.iconItem, {
            [styles.isAdded]: isAdded,
          })}
        >
          <div className={styles.icon}>
            <Icon
              iconType="flag"
              color={isAdded ? colors.shared.white : colors.shared.black}
              width={20}
              height={20}
            />
          </div>
          {translate((data.extra as IExcursion).difficulty.replace(/\s/g, ''))}
        </li>
      )}
    </ul>
  );
};

export default IconList;
