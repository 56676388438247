import { SVGProps } from 'react';

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 350 350"
    {...props}
  >
    <circle
      cx={45}
      cy={45}
      r={45}
      style={{
        fill: '#fff',
      }}
      transform="translate(-1.944 -1.944) scale(3.7733)"
      fill="#F0F"
      fillRule="evenodd"
    />
    <path
      d="M140.829 246.82c67.584 0 104.55-55.995 104.55-104.55 0-1.59 0-3.175-.107-4.75a74.738 74.738 0 0 0 18.332-19.021 73.32 73.32 0 0 1-21.106 5.78 36.869 36.869 0 0 0 16.154-20.323 73.621 73.621 0 0 1-23.332 8.919c-13.916-14.798-37.193-15.512-51.991-1.596a36.78 36.78 0 0 0-10.63 35.11 104.325 104.325 0 0 1-75.728-38.393c-9.753 16.79-4.772 38.27 11.377 49.051a36.479 36.479 0 0 1-16.678-4.599v.466c.004 17.492 12.334 32.556 29.48 36.02a36.7 36.7 0 0 1-16.593.632 36.785 36.785 0 0 0 34.33 25.517 73.736 73.736 0 0 1-45.635 15.761 74.707 74.707 0 0 1-8.746-.531 104.035 104.035 0 0 0 56.323 16.477"
      fill="#232120"
      fillRule="evenodd"
    />
  </svg>
);

export default TwitterIcon;
