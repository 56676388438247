import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M15.55 31.1c8.588 0 15.55-6.962 15.55-15.55C31.1 6.961 24.138 0 15.55 0 6.96 0 0 6.962 0 15.55 0 24.137 6.961 31.1 15.55 31.1"
      />
      <path
        fill="#232120"
        d="M11.798 12.917h1.608v-1.562c0-.69.018-1.752.518-2.41.527-.697 1.25-1.17 2.496-1.17 2.028 0 2.881.288 2.881.288l-.4 2.382s-.671-.193-1.296-.193c-.626 0-1.185.224-1.185.848v1.817h2.563l-.179 2.326H16.42v8.081h-3.014v-8.081h-1.608v-2.326"
      />
    </g>
  </svg>
);

export default FacebookIcon;
