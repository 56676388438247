import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Header1, Subtitle } from 'components/UI/Brand/text';
import loadingImg from 'assets/images/loading_training_video.gif';
import { CustomImage, FocalPointImage } from 'components';
import { ImageWithFocalPoint } from 'interfaces/IImage';
import styles from './styles.module.scss';

interface ICoverwithOverlay {
  pageTitle?: string;
  title?: string;
  isHeroVideo?: boolean;
  mediaUrl?: string;
  heroWithFocalPoint?: ImageWithFocalPoint;
}

const CoverWithOverlay = ({
  pageTitle,
  title,
  mediaUrl,
  isHeroVideo,
  heroWithFocalPoint,
}: ICoverwithOverlay) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [isFocalPointHero, setIsFocalPointHero] = useState(false);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [window]);

  useEffect(() => {
    if (
      heroWithFocalPoint &&
      heroWithFocalPoint.focalPoint.xAxis &&
      heroWithFocalPoint.focalPoint.yAxis
    ) {
      setIsFocalPointHero(true);
    }
  }, [heroWithFocalPoint]);

  return (
    <div className={styles.blackOpacityOverlay}>
      <div className={styles.overlayContainer}>
        <div className={styles.videoContainer}>
          <div className={styles.videoGradient} />
          {isHeroVideo && (
            <video
              className={clsx(styles.videoHero, styles.hero)}
              poster={loadingImg}
              autoPlay
              loop
              muted
              preload="auto"
              src={mediaUrl}
            />
          )}
          {!isHeroVideo && windowWidth > 0 && !isFocalPointHero && (
            <CustomImage
              isPresentation
              height={300}
              width={windowWidth}
              className={styles.hero}
              src={mediaUrl}
            />
          )}
          {!isHeroVideo && windowWidth > 0 && isFocalPointHero && (
            <FocalPointImage
              focalPoint={heroWithFocalPoint.focalPoint}
              title={heroWithFocalPoint.title}
              image={heroWithFocalPoint.image}
            />
          )}
        </div>
        <div className={styles.textOverlay}>
          <Header1 className={styles.overlayPageTitle}>{pageTitle}</Header1>
          <Subtitle className={styles.overlayTitle}>{title}</Subtitle>
        </div>
      </div>
    </div>
  );
};

export default CoverWithOverlay;
