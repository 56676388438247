import { useEffect, useState } from 'react';
import { Header3 } from 'components/UI/Brand/text';
import clsx from 'clsx';
import translate from 'translate';
import { IBookingAccordionItem } from 'interfaces/IAccordion';
import useScrollPosition from 'hooks/useScrollPosition';
import { BOOKING_STEPS, IChangeBookingStepParams } from 'interfaces/IBooking';
import useOnScreen from 'hooks/useOnScreen';
import { executeScroll } from 'utils/executeScroll';
import { useAppSelector } from 'hooks/storeHooks';
import { shouldEditBookingStepModalBeOpened } from 'components/UI/Modals/utils/EditBookingStepModal.util';
import { useModal } from 'hooks/useModal';
import EditBookingAccordion from 'components/UI/Modals/EditBookingStepModal/EditBookingStepModal';
import { createBookingStepId } from 'containers/Book/BookingProgress/BookingFunnel/utils/bookingStepsOrder';
import styles from './styles.module.scss';

interface IBookingAccordionProps {
  accordionItems: IBookingAccordionItem[];
  changeBookingStep: ({ direction, goTo }: IChangeBookingStepParams) => void;
  isCurrentlyActive: (stepType: BOOKING_STEPS, cabinIndex?: number) => boolean;
  bookingStepsOrder: string[];
}

const BookingAccordion = ({
  accordionItems,
  changeBookingStep,
  isCurrentlyActive,
  bookingStepsOrder,
}: IBookingAccordionProps) => {
  const { bookingStep: currentBookingStep, selectedCabinIndex } =
    useAppSelector((state) => state.bookingFunnel);

  const { isModalVisible, showModal, hideModal, RenderModal } = useModal();

  const currentlyActiveStep = createBookingStepId(
    currentBookingStep,
    selectedCabinIndex
  );

  const isDataFetching = useAppSelector((state) =>
    Object.values(state.api.queries).some(
      (query) => query && query.status === 'pending'
    )
  );

  const currentlyActiveStepIndex =
    bookingStepsOrder.indexOf(currentlyActiveStep);

  const elementToFind = `accordion-${currentlyActiveStep}-id`;

  const isElementVisible = useOnScreen(elementToFind);
  const scrollPosition = useScrollPosition();

  const [editBookingStep, setEditBookingStep] = useState<{
    step: BOOKING_STEPS;
    cabinIndex: number;
  }>();
  const onEditBookingStep = (step: BOOKING_STEPS, cabinIndex?: number) => {
    if (shouldEditBookingStepModalBeOpened(step, currentBookingStep)) {
      showModal();
      setEditBookingStep({ step, cabinIndex });
    } else {
      changeBookingStep({ goTo: { step, cabinIndex } });
    }
  };
  useEffect(() => {
    if (
      (scrollPosition !== 0 && !isElementVisible) ||
      (isDataFetching && !isElementVisible)
    ) {
      executeScroll(elementToFind, 100);
    }
  }, [elementToFind, isDataFetching]);

  return (
    <>
      <ol
        role="group"
        aria-label={translate('General_Progress')}
        id="accordionGroup"
        className={styles.list}
      >
        {accordionItems.map((accordionItem, index) => (
          <li
            key={accordionItem.id}
            className={clsx(styles.listItem, {
              [styles.listItemActive]: isCurrentlyActive(
                accordionItem.step,
                accordionItem.cabinIndex
              ),
            })}
            aria-current={
              isCurrentlyActive(accordionItem.step, accordionItem.cabinIndex)
                ? 'step'
                : null
            }
            aria-label={
              translate('General_StepXofY', index + 1, accordionItems.length) &&
              index < currentlyActiveStepIndex
                ? translate('General_Completed')
                : index !== currentlyActiveStepIndex
                  ? translate('General_Pending')
                  : ''
            }
          >
            <h3>
              <button
                disabled={accordionItem.isDisabled}
                aria-expanded={isCurrentlyActive(
                  accordionItem.step,
                  accordionItem.cabinIndex
                )}
                className={styles.accordionTrigger}
                id={`accordion-${accordionItem.id}-id`}
                onClick={() =>
                  onEditBookingStep(
                    accordionItem.step,
                    accordionItem.cabinIndex
                  )
                }
              >
                {accordionItem.accordionHeader}
                {!accordionItem.isDisabled && (
                  <span>
                    {!!accordionItem.iconLabel && (
                      <span>{accordionItem.iconLabel}</span>
                    )}
                    {index < currentlyActiveStepIndex && (
                      <p className={styles.edit}>
                        {translate('BookingSummary_Edit')}
                      </p>
                    )}
                  </span>
                )}
              </button>
            </h3>
            {isCurrentlyActive(
              accordionItem.step,
              accordionItem.cabinIndex
            ) && (
              <section
                aria-labelledby={`accordion-${accordionItem.id}-id`}
                className={styles.accordionPanel}
              >
                {accordionItem?.accordionPanelTitle && (
                  <Header3 marginBottom="four">
                    {accordionItem.accordionPanelTitle}
                  </Header3>
                )}
                {accordionItem.content}
              </section>
            )}
          </li>
        ))}
      </ol>
      {isModalVisible && (
        <RenderModal>
          <EditBookingAccordion
            changeBookingStep={changeBookingStep}
            hideModal={hideModal}
            selectedBookingStep={editBookingStep.step}
            cabinIndex={editBookingStep.cabinIndex}
          />
        </RenderModal>
      )}
    </>
  );
};

export default BookingAccordion;
