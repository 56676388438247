import { KeyboardEvent, ReactNode } from 'react';
import styles from './styles.module.scss';

interface ICustomLinkProps {
  onClick: () => void;
  fontFamily?: string;
  fontSize?: number;
  id?: string;
  children?: ReactNode | undefined;
}

const CustomLink = ({
  children,
  onClick,
  fontFamily,
  fontSize,
  id,
}: ICustomLinkProps) => (
  <span
    tabIndex={0}
    id={id}
    onClick={onClick}
    role="link"
    onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    }}
    className={styles.link}
    style={{ fontFamily, fontSize }}
  >
    {children}
  </span>
);

export default CustomLink;
