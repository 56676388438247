import translate from 'translate';
import { INews, INewsLink } from 'interfaces/INewsState';
import NewsCard from './NewsCard';
import styles from './styles.module.scss';

const getNewsLink = ({ slug, newsType }: INewsLink) => {
  switch (newsType) {
    case 'CampaignPage':
      return `/learn/campaign/${slug}`;
    case 'Article':
    default:
      return `/learn/article/${slug}`;
  }
};

interface INewsList {
  news: INews[];
  lengthOfList?: number;
  labelledby?: string;
  hasItemsBorder?: boolean;
}

const NewsList = ({
  news,
  lengthOfList,
  labelledby,
  hasItemsBorder = false,
}: INewsList) => (
  <ul
    className={styles.cardsContainer}
    aria-label={translate('news_List')}
    aria-labelledby={labelledby}
  >
    {news
      .slice(0, lengthOfList)
      .map(({ id, title, intro, image, slug, newsType }) => (
        <NewsCard
          key={id}
          title={title}
          intro={intro}
          image={image}
          id={id}
          link={getNewsLink({ slug, newsType })}
          labelledby="news-list"
          hasItemsBorder={hasItemsBorder}
        />
      ))}
  </ul>
);

export default NewsList;
