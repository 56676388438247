import { IDestination, IDestinationItem } from 'interfaces/IDestination';
import {
  IIncludedSailings,
  IPromotionCollection,
} from 'interfaces/IPromotions';
import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchPromotionStart,
  fetchPromotionSuccess,
  fetchPromotionFailed,
  fetchPromotionVoyagesStart,
  fetchPromotionVoyagesSuccess,
  fetchPromotionVoyagesFailed,
  fetchPromotionDestinationsStart,
  fetchPromotionDestinationsSuccess,
  fetchPromotionDestinationsFailed,
  resetPromotions,
} = createActions({
  FETCH_PROMOTION_START: undefined,
  FETCH_PROMOTION_SUCCESS: (
    promotionsCollection: IPromotionCollection,
    shouldCombinePreviouslyFetchedWithRecentlyFetchedPromotions?: boolean
  ) => ({
    promotionsCollection,
    shouldCombinePreviouslyFetchedWithRecentlyFetchedPromotions,
  }),
  FETCH_PROMOTION_FAILED: (error) => ({ error }),
  FETCH_PROMOTION_VOYAGES_START: undefined,
  FETCH_PROMOTION_VOYAGES_SUCCESS: (includedSailings: IIncludedSailings) => ({
    includedSailings,
  }),
  FETCH_PROMOTION_VOYAGES_FAILED: (error) => ({ error }),
  FETCH_PROMOTION_DESTINATIONS_START: undefined,
  FETCH_PROMOTION_DESTINATIONS_SUCCESS: (
    destinations: IDestinationItem[],
    id: string
  ) => ({
    destinations,
    promotionId: id,
  }),
  FETCH_PROMOTION_DESTINATIONS_FAILED: (error) => ({ error }),
  RESET_PROMOTIONS: undefined,
});

export const fetchPromotion =
  (promoId: string, limitIncludeSailings = 4, offsetIncludeSailings = 0) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPromotionStart());
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}Promotions/${promoId}?limitIncludeSailings=${limitIncludeSailings}&offsetIncludeSailings=${offsetIncludeSailings}`
      )
      .then((res) => {
        const promotionCollection: IPromotionCollection = {
          promotions: [res.data],
        };
        dispatch(fetchPromotionSuccess(promotionCollection));
      })
      .catch((err) => {
        dispatch(fetchPromotionFailed(err));
      });
  };

export interface IFetchPromotionVoyageQuery {
  promotionId: string;
  limit?: number;
  offset?: number;
  destinationId?: string;
}

export const fetchPromotionVoyages =
  ({
    promotionId,
    limit = 3,
    offset = 0,
    destinationId,
  }: IFetchPromotionVoyageQuery) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPromotionVoyagesStart());
    const query = new URLSearchParams();
    query.set('promotionId', promotionId);
    query.set('limit', limit.toString());
    query.set('offset', offset.toString());
    let hasFilter = false;
    if (destinationId && destinationId !== 'All') {
      query.set('destinationId', destinationId);
      hasFilter = true;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}Promotions/${promotionId}/voyages?${query}`
      )
      .then((res) => {
        dispatch(fetchPromotionVoyagesSuccess({ ...res.data, hasFilter }));
      })
      .catch((err) => {
        dispatch(fetchPromotionVoyagesSuccess(err));
      });
  };

export const fetchPromotionVoyagesDestinations =
  (promoId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPromotionDestinationsStart());
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}Promotions/${promoId}/voyages/destinations`
      )
      .then((res) => {
        dispatch(
          fetchPromotionDestinationsSuccess(
            res.data.map(
              ({ id, heading }: Pick<IDestination, 'id' | 'heading'>) => ({
                value: id,
                label: heading,
              })
            ),
            promoId
          )
        );
      })
      .catch((err) => {
        dispatch(fetchPromotionDestinationsFailed(err));
      });
  };
