import { createActions } from 'redux-actions';
import axios from 'axios-instance';
import { getJwtPayload } from 'utils/analytics';
import translate from 'translate';
import noImage from 'assets/images/group-2@3x.png';
import { FAILED, SUCCESS } from 'utils/constants';
import { createHeaderMessage } from './headerMessage';

export const {
  fetchProfileDataStart,
  fetchProfileDataSuccess,
  fetchProfileDataFailed,
  updateProfileData,
  clearProfileDataStart,
  updateProfileAgencyStart,
  updateProfileAgencySuccess,
  updateProfileAgencyFailed,
  fetchProfileImageStart,
  fetchProfileImageSuccess,
  fetchProfileImageFailed,
  savePasswordStart,
  savePasswordSuccess,
  savePasswordFailed,
} = createActions({
  FETCH_PROFILE_DATA_START: undefined,
  FETCH_PROFILE_DATA_SUCCESS: (profileData) => ({ profileData }),
  FETCH_PROFILE_DATA_FAILED: (error) => ({ error }),
  UPDATE_PROFILE_DATA: (profileData) => ({ profileData }),
  CLEAR_PROFILE_DATA_START: undefined,
  UPDATE_PROFILE_AGENCY_START: undefined,
  UPDATE_PROFILE_AGENCY_SUCCESS: (agencyData) => ({ agencyData }),
  UPDATE_PROFILE_AGENCY_FAILED: (error) => ({ error }),
  FETCH_PROFILE_IMAGE_START: undefined,
  FETCH_PROFILE_IMAGE_SUCCESS: (profileImageUrl) => ({ profileImageUrl }),
  FETCH_PROFILE_IMAGE_ERROR: (error) => ({ error }),
  SAVE_PASSWORD_START: undefined,
  SAVE_PASSWORD_SUCCESS: (email, password) => ({ email, password }),
  SAVE_PASSWORD_FAILED: undefined,
});

export const fetchProfileData = () => (dispatch) => {
  dispatch(fetchProfileDataStart());
  axios
    .post(`${process.env.REACT_APP_API_BASE_URL}user/email/`, {
      email: getJwtPayload().email,
    })
    .then((res) => {
      dispatch(fetchProfileDataSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchProfileDataFailed(err));
    });
};

export const onUpdateProfileAgency = (agencyData) => (dispatch) => {
  dispatch(updateProfileAgencyStart());
  axios
    .patch(`${process.env.REACT_APP_API_BASE_URL}user/agency`, agencyData)
    .then(() => {
      dispatch(updateProfileAgencySuccess(agencyData));
      dispatch(
        createHeaderMessage(
          SUCCESS,
          translate('General_MessageSuccess'),
          translate('MyProfile_EditAgencyInfo_YourAgencyInfoHasBeenUpdated')
        )
      );
    })
    .catch((err) => {
      dispatch(updateProfileAgencyFailed(err));
      dispatch(
        createHeaderMessage(
          FAILED,
          translate('General_MessageFailed'),
          translate('MyProfile_EditAgencyInfo_CheckYourInternetConnection')
        )
      );
    });
};

export const onUpdateProfileData = (profileData) => (dispatch) =>
  dispatch(updateProfileData(profileData));

export const clearProfileData = () => (dispatch) =>
  dispatch(clearProfileDataStart());

export const fetchProfileImage = (agentId) => (dispatch) => {
  dispatch(fetchProfileImageStart());
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}user/agent/${agentId}/image`)
    .then((res) => {
      dispatch(fetchProfileImageSuccess(res.data.image || noImage));
    })
    .catch(() => {
      dispatch(
        fetchProfileImageFailed(
          translate('MyProfile_EditProfile_LogoDownloadFailed')
        )
      );
    });
};

export const savePassword = (email, password) => (dispatch) => {
  dispatch(savePasswordStart());
  axios
    .patch(`${process.env.REACT_APP_API_BASE_URL}user/password`, {
      email,
      password,
    })
    .then(() => {
      dispatch(savePasswordSuccess());
      dispatch(
        createHeaderMessage(
          SUCCESS,
          translate('General_MessageSuccess'),
          translate('MyProfile_EditAccount_YourNewPasswordHasBeenSaved')
        )
      );
    })
    .catch(() => {
      dispatch(fetchProfileImageFailed());
      dispatch(
        createHeaderMessage(
          FAILED,
          translate('General_MessageFailed'),
          translate('MyProfile_EditAccount_PleaseCheckYourInternet')
        )
      );
    });
};
