import translate from 'translate';
import { COASTAL_OPTIONS_MAP } from 'interfaces/IItineraryByVoyage';
import { COASTAL_OPTIONS } from 'utils/constants';
import { useAppSelector, useAppDispatch } from 'hooks/storeHooks';
import { ChangeEvent, useEffect } from 'react';
import { fetchTourNames } from 'store/actions/tourNames';
import { IVehicle } from 'interfaces/IVehicle';
import { defaultVehiclesInputValues } from 'components/UI/VehiclesInput/VehiclesInput';
import {
  resetPackageStartDay,
  resetSearchParams,
  resetVehicles,
} from 'store/actions';
import { Label } from 'components/UI/Brand/text';
import Dropdown from 'components/UI/Select/Dropdown';
import { IOption } from 'interfaces/IOption';

interface IVoyageDropdownProps {
  setSelectedVoyage: (setSelectedVoyage: COASTAL_OPTIONS) => void;
  setIsP2p: (setIsP2p: boolean) => void;
  setClassicVoyageId: (classicVoyageId: string) => void;
  setIsViaKirkenes: (isViaKirkenes: boolean) => void;
  selectedVoyage: COASTAL_OPTIONS;
  setVehicles: (vehicles: IVehicle) => void;
  setPromotionSearch: (value: boolean) => void;
}

export const VoyageDropdown = ({
  setSelectedVoyage,
  setIsP2p,
  setClassicVoyageId,
  setIsViaKirkenes,
  setVehicles,
  selectedVoyage,
  setPromotionSearch,
}: IVoyageDropdownProps) => {
  const dispatch = useAppDispatch();
  const {
    NORTH_CAPE_EXPRESS,
    P2P,
    SVALBARD_EXPRESS,
    SPITSBERGEN_ADVENTURER,
    HURTIGRUTEN_HAMBURG,
    CLASSIC_VOYAGE,
  } = COASTAL_OPTIONS;

  const { tourNames, loading } = useAppSelector((state) => state.tourNames);

  let voyageDropDownOptions = tourNames.map(
    (voyage) =>
      ({
        value: voyage.value,
        description: voyage.label,
      }) as IOption
  ) as IOption[];

  if (loading) {
    voyageDropDownOptions.unshift({
      value: 'loading',
      description: translate('Coastal_Search_Iconic_Voyage_Loading'),
    });
  }

  voyageDropDownOptions = voyageDropDownOptions.filter(
    (_option) =>
      ![
        NORTH_CAPE_EXPRESS,
        SVALBARD_EXPRESS,
        SPITSBERGEN_ADVENTURER,
        HURTIGRUTEN_HAMBURG,
      ].includes(COASTAL_OPTIONS_MAP[_option.value] as COASTAL_OPTIONS)
  );

  voyageDropDownOptions.unshift({
    value: 'defaultValue',
    description: translate('Coastal_Search_Iconic_Voyage_Default'),
    disabled: true,
  });

  const onVoyageChange = (voyage: COASTAL_OPTIONS) => {
    dispatch(resetVehicles()); // remove vehicle data from search store
    dispatch(resetSearchParams());
    setIsP2p(voyage === P2P);
    setClassicVoyageId(''); // reset so default ('select an itinerary') is selected
    setIsViaKirkenes(false); // reset p2p radio selection to default ('one way')
    setSelectedVoyage(voyage);
    setVehicles(defaultVehiclesInputValues);
    dispatch(resetPackageStartDay());
    setPromotionSearch(false);
  };

  useEffect(() => {
    if (tourNames.length === 0) dispatch(fetchTourNames('HRN'));
    if (selectedVoyage === CLASSIC_VOYAGE) {
      dispatch(resetPackageStartDay());
    }
  }, []);

  return (
    <>
      <Label htmlFor="voyage-dropdown">
        {translate('BookingProgressHeader_SubTitleVoyage')}*
      </Label>
      <Dropdown
        id="voyage-dropdown"
        options={voyageDropDownOptions}
        value={selectedVoyage}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          onVoyageChange(event.currentTarget.value as COASTAL_OPTIONS)
        }
      />
    </>
  );
};
