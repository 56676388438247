import { IPromotion } from 'interfaces/IPromotions';
import translate from 'translate';
import { getFullDateFormat } from 'utils/multilanguage-support';
import NewsCard from 'components/News/NewsCard';
import { dataLayerPush, getAnalyticsPromotion } from 'utils/analytics';
import { IAnalyticsPromotion } from 'interfaces/IAnalytics';
import { convertToUTC, getCalendarLocale } from 'utils/dates';
import { getLanguage } from 'utils/getLanguage';
import { format } from 'date-fns';
import styles from './styles.module.scss';

interface IPromotionsList {
  promotions: IPromotion[];
  lengthOfList?: number;
  labelledby?: string;
}

const PromotionsList = ({
  promotions,
  lengthOfList,
  labelledby,
}: IPromotionsList) => {
  const onTrackPromotion = (analyticsData: IAnalyticsPromotion) => {
    dataLayerPush('productClick', analyticsData);
  };
  const language = getLanguage();
  const locale = getCalendarLocale(language);

  return (
    <ul
      className={styles.promotionsList}
      aria-label={translate('Promotions_List')}
      aria-labelledby={labelledby}
    >
      {promotions
        .filter((promotion) => promotion !== null)
        .slice(0, lengthOfList)
        .map(
          ({ id, title, intro, image, urlName, promotionEndDate }, index) => (
            <NewsCard
              key={id}
              id={id}
              link={`/promotions/${urlName}`}
              labelledby="promotions-list"
              title={title}
              intro={intro}
              imageURL={image}
              badge={`${translate('General_ValidUntil')} ${format(convertToUTC(promotionEndDate), getFullDateFormat(), { locale })}`}
              onTrackPromotion={() => {
                const analyticsData = getAnalyticsPromotion(
                  [{ title, position: `${index + 1}`, id, hero: image }],
                  'click'
                );
                onTrackPromotion(analyticsData);
              }}
              hasItemsBorder
            />
          )
        )}
    </ul>
  );
};

export default PromotionsList;
