import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { Logo } from 'components/UI/Logo/util';
import HurtigrutenLogo from 'components/UI/Logo/HurtigrutenLogo';
import Icon from 'components/UI/Icons/Icon';
import { AlertInfoBanner } from 'components';
import translate from 'translate';
import { getJwtPayload, track } from 'utils/analytics';
import { hasValidToken } from 'utils/getToken';
import HxLogo from 'components/UI/Logo/HxLogo';
import { Region, getRegionByLocale } from 'utils/regions';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';
import SignupLoginButtonGroup from '../SignupLoginButtonGroup/SignupLoginButtonGroup';
import MobileHeaderItem from '../MobileHeaderItem';
import SplitHeader from '../SplitHeader';

interface IStyledProps {
  fixed?: boolean;
  isFilledHeader: boolean;
}

interface IMobileHeaderProps extends IStyledProps {
  isAuth: boolean;
  hasAlertBanner: boolean;
  hasSplitBanner: boolean;
}

const { REACT_APP_COMPANY } = process.env;

const MobileHeader = (props: IMobileHeaderProps) => {
  const {
    isAuth,
    isFilledHeader,
    fixed = true,
    hasAlertBanner,
    hasSplitBanner,
  } = props;
  const [open, setOpen] = useState(false);

  const trackBook = () => isAuth && track('Click book');
  const trackLearn = () => track('Click learn');
  const trackResources = () => track('Click resources');
  const trackMyBookings = () => track('Click my bookings');
  const trackMyProfile = () => track('Click my profile');
  const trackAgencyArea = () => track('Click Agenturbereic German');
  const region = getRegionByLocale(getLanguage());

  const germanRegions =
    region === Region.Germany ||
    region === Region.Austria ||
    region === Region.Switzerland;
  const renderUnprotectedSmallScreen = () =>
    window.innerWidth < 355 && (
      <>
        <div className={styles.contentRoot}>
          <MobileHeaderItem
            to="/login"
            closeMenu={() => {
              setOpen(false);
            }}
          >
            {translate('Navbar_Login')}
          </MobileHeaderItem>
          <MobileHeaderItem
            to="/onboard"
            closeMenu={() => {
              setOpen(false);
            }}
          >
            {translate('General_SignUp')}
          </MobileHeaderItem>
        </div>
      </>
    );

  const renderProtected = () => {
    // function canRegionShowTraining() {
    //   let marketId = null;
    //   if (isAuth) {
    //     marketId = getJwtPayload()['http://user/market'].toLowerCase();
    //   }
    //   const region = getRegionId(marketId);
    //   return region !== Region.Germany && region !== Region.Austria;
    // }
    const marketId = getJwtPayload()['http://user/market'];
    return (
      <div className={styles.contentRoot}>
        <MobileHeaderItem
          to={
            hasValidToken()
              ? REACT_APP_COMPANY === 'HRN'
                ? '/book/coastal'
                : '/book/expedition'
              : '/'
          }
          closeMenu={() => {
            trackBook();
            setOpen(false);
          }}
        >
          <ButtonText>{translate('Navbar_Home')}</ButtonText>
        </MobileHeaderItem>

        {(REACT_APP_COMPANY === 'HX' || !REACT_APP_COMPANY) && (
          <MobileHeaderItem
            to="/book/expedition"
            closeMenu={() => {
              trackBook();
              setOpen(false);
            }}
          >
            <ButtonText>{translate('SearchBar_Tabs_Expedition')}</ButtonText>
          </MobileHeaderItem>
        )}

        {(REACT_APP_COMPANY === 'HRN' || !REACT_APP_COMPANY) && (
          <MobileHeaderItem
            to="/book/coastal"
            closeMenu={() => {
              trackBook();
              setOpen(false);
            }}
          >
            <ButtonText>
              {translate('SearchBar_Tabs_NorwegianCoastal')}
            </ButtonText>
          </MobileHeaderItem>
        )}
        <MobileHeaderItem
          to="/learn"
          closeMenu={() => {
            trackLearn();
            setOpen(false);
          }}
        >
          <ButtonText>{translate('Navbar_Learn')}</ButtonText>
        </MobileHeaderItem>

        {/* {canRegionShowTraining() && (
          <MobileHeaderItem
            to="/training"
            closeMenu={() => {
              trackGetCertified();
              setOpen(false);
            }}
          >
            <ButtonText>{translate('Navbar_Training')}</ButtonText>
          </MobileHeaderItem>
        )} */}

        <MobileHeaderItem
          to="/marketing"
          closeMenu={() => {
            trackResources();
            setOpen(false);
          }}
        >
          <ButtonText>{translate('Navbar_Marketing')}</ButtonText>
        </MobileHeaderItem>
        {marketId === 'DE' && (
          <MobileHeaderItem
            to={
              REACT_APP_COMPANY === 'HX'
                ? '/learn/articleContainer/48WIPkoHRvQi0Fo6vhzdGa'
                : '/learn/articleContainer/1tvz3TNuNGxSatNCW7OfQe'
            }
            closeMenu={() => {
              trackAgencyArea();
              setOpen(false);
            }}
          >
            <ButtonText>{translate('Navbar_AgencyArea')}</ButtonText>
          </MobileHeaderItem>
        )}
        <MobileHeaderItem
          to="/bookings"
          closeMenu={() => {
            trackMyBookings();
            setOpen(false);
          }}
        >
          <ButtonText>{translate('Navbar_MyBookings')}</ButtonText>
        </MobileHeaderItem>
        <MobileHeaderItem
          to="/myprofile"
          closeMenu={() => {
            trackMyProfile();
            setOpen(false);
          }}
        >
          <ButtonText>{translate('General_MyProfile')}</ButtonText>
        </MobileHeaderItem>
      </div>
    );
  };

  const renderUnprotected = () => (
    <SignupLoginButtonGroup isFilledHeader isMobile />
  );

  return (
    <>
      <div
        className={clsx(styles.menuHeaderRoot, {
          [styles.fixed]: fixed,
          [styles.isFilledHeader]: isFilledHeader,
        })}
      >
        <NavLink
          to={
            hasValidToken()
              ? REACT_APP_COMPANY === 'HRN'
                ? '/book/coastal'
                : '/book/expedition'
              : '/'
          }
        >
          <div className={styles.logoContainer}>
            {REACT_APP_COMPANY === 'HX' && (
              <HxLogo
                logoType={
                  isFilledHeader ? Logo.MAIN_HX_BLUE : Logo.MAIN_WHITE_FONT
                }
              />
            )}
            {(REACT_APP_COMPANY === 'HRN' || !REACT_APP_COMPANY) && (
              <HurtigrutenLogo logoType={Logo.MAIN_BLACK_FONT} />
            )}
          </div>
        </NavLink>
        {!isAuth && window.innerWidth >= 355 ? (
          !window.location.href.includes('onboard') &&
          !window.location.href.includes('login') &&
          renderUnprotected()
        ) : (
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className={styles.menu}
          >
            {!window.location.href.includes('onboard') &&
              !window.location.href.includes('login') &&
              (open ? (
                <div
                  style={{
                    minWidth: 28,
                    minHeight: 28,
                    maxWidth: 28,
                    maxHeight: 28,
                  }}
                >
                  <Icon iconType="cross" height={28} width={28} />
                </div>
              ) : (
                <div
                  style={{
                    minWidth: 32,
                    minHeight: 32,
                    maxWidth: 32,
                    maxHeight: 32,
                  }}
                >
                  <Icon iconType="menu" height={32} width={32} />
                </div>
              ))}
          </div>
        )}
      </div>
      {hasAlertBanner && (
        <div className={styles.alertBanner}>
          <AlertInfoBanner />
          {hasSplitBanner &&
            (!germanRegions || REACT_APP_COMPANY !== 'HRN') && <SplitHeader />}
        </div>
      )}

      {open && (isAuth ? renderProtected() : renderUnprotectedSmallScreen())}
    </>
  );
};

export default MobileHeader;
