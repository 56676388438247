import { createActions } from 'redux-actions';
import axios from '../../axios-instance';
import { storeSupportInfo } from '../../utils/supportInfo';

export const {
  fetchLandingPageStart,
  fetchLandingPageSuccess,
  fetchLandingPageFailed,
  setFooterData,
  fetchContactDataFailed,
} = createActions({
  FETCH_LANDING_PAGE_START: (publicIp) => ({ publicIp }),
  FETCH_LANDING_PAGE_SUCCESS: (landingPageData) => ({ landingPageData }),
  FETCH_LANDING_PAGE_FAILED: (error) => ({ error }),
  SET_FOOTER_DATA: (footerData) => ({ footerData }),
  FETCH_CONTACT_DATA_FAILED: (error) => ({ error }),
});

export const fetchLandingPageData = (ip) => (dispatch) => {
  dispatch(fetchLandingPageStart(ip));
  const { REACT_APP_COMPANY } = process.env;

  const productType =
    REACT_APP_COMPANY === 'HRN' ? 'Hurtigruten' : REACT_APP_COMPANY;

  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}LandingPage`, {
      headers: {
        'x-fallback-ip': ip,
      },
      params: {
        productType,
      },
    })
    .then((res) => {
      dispatch(fetchLandingPageSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchLandingPageFailed(err));
    });
};
export const onFooterData = (footerData) => (dispatch) => {
  storeSupportInfo(
    (({ email, phone, mailingAddress }) => ({
      email,
      phone,
      mailingAddress,
    }))(footerData)
  );
  dispatch(setFooterData(footerData));
};

export const fetchContactData = (market) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}contact/${market}`)
    .then((res) => {
      dispatch(onFooterData(res.data));
    })
    .catch(() => {
      dispatch(fetchContactDataFailed());
    });
};
