import { IHomePageData } from 'interfaces/IHomePage';
import { api } from './api';

// only being used for homepage heading & accessibleCabinInformation
export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHomePage: build.query<IHomePageData, void>({
      query: () => ({
        url: `Home`,
      }),
    }),
  }),
});

export const { useGetHomePageQuery } = homeApi;

export const {
  endpoints: { getHomePage },
} = homeApi;
