import {
  useState,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  forwardRef,
  CSSProperties,
} from 'react';
import Icon from 'components/UI/Icons/Icon';
import debounce from 'utils/debounce';
import { TIcon } from 'components/UI/Icons/Icon.type';
import translate from 'translate';
import { colors } from 'style/colors';
import TextInputComponent from './TextInputComponent';
import Textarea from './TextArea';
import { HelperText, Label } from '../Brand/text';
import styles from './styles.module.scss';

export type TextInputChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>;

export interface ITextInputComponent {
  disabled?: boolean;
  error?: boolean;
  isAutoFocus?: boolean;
  isPassword?: boolean;
  isRequired?: boolean;
  multiline?: boolean;
  className?: string;
  debounced?: boolean;
  describedbyId?: string;
  helperText?: string;
  id?: string;
  initialValue?: string;
  label?: string;
  leftIconName?: string;
  name: string;
  placeholder?: string;
  type?: string;
  value?: string;
  style?: CSSProperties;

  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (event: TextInputChangeEvent) => void;
  onKeyDown?: (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const TextInput = forwardRef<HTMLInputElement, ITextInputComponent>(
  (
    {
      disabled = false,
      error = false,
      isAutoFocus = false,
      isPassword = false,
      isRequired = false,
      multiline = false,
      debounced = false,
      className,
      describedbyId = 'form_helper_text',
      helperText,
      id,
      label,
      leftIconName,
      name,
      placeholder,
      type = 'text',
      value,
      initialValue = '',
      style,

      onBlur,
      onChange,
      onKeyDown,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [innerValue, setInnerValue] = useState(value || initialValue);

    useEffect(() => {
      setInnerValue(value);
    }, [value]);

    const onChangeDebounced: (event: ChangeEvent<HTMLInputElement>) => void =
      useCallback(
        debounce(
          (event: ChangeEvent<HTMLInputElement>) => onChange(event),
          500
        ),
        []
      );

    const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
      event.preventDefault();
    };

    return (
      <div className={styles.root} style={style}>
        {label && (
          <Label htmlFor={id} hasError={error}>
            {label}
          </Label>
        )}
        {isPassword ? (
          <div className={styles.inputContainer}>
            <TextInputComponent
              id={id}
              required={isRequired}
              disabled={disabled}
              name={name}
              value={value}
              className={className}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              type={showPassword ? 'text' : 'password'}
              hasError={error}
              aria-invalid={error ? 'true' : 'false'}
              aria-describedby={describedbyId}
              autoFocus={isAutoFocus}
              ref={ref}
            />
            <button
              type="button"
              className={styles.passwordIcon}
              aria-label={translate(
                showPassword
                  ? 'SignUp_Aria_HidePassword'
                  : 'SignUp_Aria_ShowPassword'
              )}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              onMouseDown={handleMouseDownPassword}
            >
              <Icon
                iconType={showPassword ? 'eyeLess' : 'eye'}
                color={colors.shared.textBlack}
                width={32}
                height={22}
              />
            </button>
          </div>
        ) : (
          <div className={styles.inputContainer}>
            {leftIconName && (
              <div className={styles.leftIcon}>
                <Icon
                  iconType={leftIconName as TIcon}
                  color={colors.shared.warmGray6}
                  width={24}
                  height={24}
                />
              </div>
            )}
            {multiline ? (
              <Textarea
                id={id}
                required={isRequired}
                name={name}
                value={value}
                onKeyDown={onKeyDown}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                hasError={error}
                aria-invalid={error ? 'true' : 'false'}
                aria-describedby={describedbyId}
                autoFocus={isAutoFocus}
                style={{
                  paddingLeft: leftIconName ? 56 : 12,
                  paddingRight: error ? 52 : 12,
                }}
              />
            ) : (
              <>
                <TextInputComponent
                  id={id}
                  required={isRequired}
                  name={name}
                  value={debounced ? innerValue : value}
                  onKeyDown={onKeyDown}
                  disabled={disabled}
                  placeholder={placeholder}
                  className={className}
                  onChange={
                    debounced
                      ? (event: ChangeEvent<HTMLInputElement>) => {
                          onChangeDebounced({
                            ...event,
                            target: {
                              ...event.target,
                              value: event.target.value,
                              name: event.target.name,
                            },
                          });
                          setInnerValue(event.target.value);
                        }
                      : onChange
                  }
                  onBlur={onBlur}
                  type={type || 'text'}
                  hasError={error}
                  aria-invalid={error ? 'true' : 'false'}
                  aria-describedby={describedbyId}
                  autoFocus={isAutoFocus}
                  style={{
                    paddingLeft: leftIconName ? 56 : 12,
                    paddingRight: error ? 52 : 12,
                  }}
                  ref={ref}
                />
                {error && (
                  <div className={styles.rightIcon}>
                    <Icon
                      iconType="circleWithExclamation"
                      color={
                        process.env.REACT_APP_COMPANY === 'HX'
                          ? colors.hrx.blue
                          : colors.shared.red
                      }
                      width={24}
                      height={24}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!!helperText && (
          <HelperText hasError={error} id={describedbyId}>
            {helperText}
          </HelperText>
        )}
      </div>
    );
  }
);

export default TextInput;
