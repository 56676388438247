import { handleActions } from 'redux-actions';
import * as actions from '../actions/modals';

const initialState = {
  isAddExtraModalOpen: false,
  addExtraModalData: null,
};

const reducer = handleActions(
  {
    [actions.openAddExtraModal]: (state, action) => ({
      ...state,
      addExtraModalData: action.payload.addExtraModalData,
      isAddExtraModalOpen: true,
    }),
    [actions.closeAddExtraModal]: (state) => ({
      ...state,
      isAddExtraModalOpen: false,
      addExtraModalData: null,
    }),
  },
  initialState
);

export default reducer;
