import { createActions } from 'redux-actions';
import axios from 'axios';

export const {
  leadCreated,
  registrationStart,
  registrationSuccess,
  registrationFailed,
  registrationClear,
} = createActions({
  LEAD_CREATED: undefined,
  REGISTRATION_START: undefined,
  REGISTRATION_SUCCESS: (registrationResponse) => ({ registrationResponse }),
  REGISTRATION_FAILED: (error) => ({ error }),
  REGISTRATION_CLEAR: undefined,
});

export const registration =
  (registrationData, migrate = false, publicIp = null) =>
  (dispatch) => {
    const url = migrate
      ? `${process.env.REACT_APP_API_BASE_URL}User/migrate`
      : `${process.env.REACT_APP_API_BASE_URL}User`;
    const { REACT_APP_COMPANY } = process.env;

    dispatch(registrationStart());
    axios
      .post(url, registrationData, {
        headers: {
          'x-fallback-ip': publicIp || '',
        },
        params: {
          productType: REACT_APP_COMPANY,
        },
      })
      .then((response) => {
        if (response.headers?.location === 'Lead created') {
          dispatch(leadCreated());
        }
        dispatch(registrationSuccess(response.data));
      })
      .catch((error) => {
        dispatch(registrationFailed(error.response.data));
        const errorToLog = { ...error };
        if (errorToLog && errorToLog.config && errorToLog.config.data) {
          const errorData = { ...registrationData };
          delete errorData.password;
          errorToLog.config.data = errorData;
        }
      });
  };
