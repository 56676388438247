import { handleActions } from 'redux-actions';
import { onboardingStep } from 'utils/constants';
import * as actions from '../actions/onboarding';

const initialState = {
  agentData: null,
  agencyData: null,
  selectedAgencyId: null,
  agencyId: null,
  email: null,
  password: null,
  region: null,
  agentAddress: null,
  userWantToRegisterAgency: false,
  userSearchedAgencies: false,
  confirmedAgencyData: false,
  activeStep: onboardingStep.AgentRegistration,
  leadValuesLoading: false,
  agencyValidValues: null,
  agencyValuesLoading: false,
  leadValidValues: null,
  agentValidValues: null,
  agentValuesLoading: false,
};

const reducer = handleActions(
  {
    [actions.resetOnboardData]: () => ({
      ...initialState,
    }),
    [actions.saveOnboardData]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [actions.fetchLeadValuesStart]: (state) => ({
      ...state,
      leadValuesLoading: true,
    }),
    [actions.fetchLeadValuesSuccess]: (state, action) => ({
      ...state,
      leadValidValues: action.payload.values,
      error: null,
      leadValuesLoading: false,
    }),
    [actions.fetchLeadValuesFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      leadValuesLoading: false,
    }),
    [actions.fetchAgentValuesStart]: (state) => ({
      ...state,
      agentValuesLoading: true,
    }),
    [actions.fetchAgentValuesSuccess]: (state, action) => ({
      ...state,
      agentValidValues: action.payload.values,
      error: null,
      agentValuesLoading: false,
    }),
    [actions.fetchAgentValuesFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      agentValuesLoading: false,
    }),
    [actions.fetchAgencyValuesStart]: (state) => ({
      ...state,
      agencyValuesLoading: true,
    }),
    [actions.fetchAgencyValuesSuccess]: (state, action) => ({
      ...state,
      agencyValidValues: action.payload.values,
      error: null,
      agencyValuesLoading: false,
    }),
    [actions.fetchAgencyValuesFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      agencyValuesLoading: false,
    }),
  },
  initialState
);

export default reducer;
