import clsx from 'clsx';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cancelQuote, logout } from 'store/actions';
import { hasValidToken } from 'utils/getToken';
import useBoolean from 'hooks/useBoolean';
import MainFooter from 'components/UI/MainFooter/MainFooter';
import MainHeader from 'components/Header/MainHeader';
import MobileHeader from 'components/Header/MobileHeader/MobileHeader';
import './Layout.css';
import { WHITE_LIST_LINKS } from 'utils/constants';
import styles from './styles.module.scss';

const pagesWithGreayBackground = [
  'payment',
  '/book/progress/paymentresult',
  '/myprofile',
  'myprofile/changepassword',
];

const Layout = ({ children }) => {
  const [showLearnNavigation, setShowLearnNavigation] = useState(false);
  const [learnButtonActive, setLearnButtonActive] = useState(false);
  const [useFixedHeader, setUseFixedHeder] = useState(true);
  const { quoteID } = useSelector((state) => state.bookingFunnel);
  const [isScrolled, setScrolled, unsetScrolled] = useBoolean(false);
  const features = useSelector((state) => state.feature.flags);
  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [showSplitBanner, setShowSplitBanner] = useState(false);
  const { REACT_APP_COMPANY } = process.env;

  const dispatch = useDispatch();
  const location = useLocation();
  const isGreyLayout = !!pagesWithGreayBackground.find((part) =>
    location.pathname.includes(part)
  );
  const isAuthenticated = hasValidToken();
  const { isContentHeader, hasInfoBanner } = useSelector((state) => ({
    isAuthenticated,
    isContentHeader: state.headerState.isContentHeader,
    hasInfoBanner: state.headerState.hasInfoBanner,
  }));

  const shouldRenderFilledHeader = () =>
    isScrolled || location.pathname !== '/' || window.innerWidth < 355;

  const isFooterLinks = WHITE_LIST_LINKS.includes(location?.pathname);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -15) {
      setScrolled();
    }
    if (currPos.y >= -15 && prevPos.y < -15) {
      unsetScrolled();
    }
  });

  useEffect(() => {
    // don't trigger in development mode due to e2e test
    if (
      !isAuthenticated &&
      !isFooterLinks &&
      process.env.NODE_ENV !== 'development'
    ) {
      dispatch(logout());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!location.pathname.includes('progress') && quoteID) {
      dispatch(cancelQuote(quoteID));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (showLearnNavigation && !learnButtonActive) {
      setShowLearnNavigation(false);
    }
  }, [learnButtonActive]);

  useEffect(() => {
    if (showLearnNavigation) {
      setLearnButtonActive(true);
    }
  }, [showLearnNavigation]);

  useEffect(() => {
    setUseFixedHeder(!isContentHeader);
  }, [isContentHeader]);

  useEffect(() => {
    if (features?.AlertInfoBanner) {
      setShowAlertBanner(true);
    }
  }, [features]);
  useEffect(() => {
    if (features?.SplitInfoBanner) {
      setShowSplitBanner(true);
    }
  }, [features]);

  const hasAlertBanner = features?.AlertInfoBanner && hasInfoBanner;

  return (
    <>
      <div
        id="animatedMobileHeader"
        className={
          isAuthenticated ? styles.authMobileHeader : styles.mobileHeader
        }
      >
        <MobileHeader
          fixed={useFixedHeader}
          isAuth={isAuthenticated}
          isFilledHeader={shouldRenderFilledHeader()}
          hasAlertBanner={showAlertBanner}
          hasSplitBanner={showSplitBanner}
        />
      </div>
      <div
        className={
          isAuthenticated ? styles.authDesktopHeader : styles.desktopHeader
        }
      >
        <MainHeader
          fixed={useFixedHeader}
          isAuth={isAuthenticated}
          setLearnButtonActive={setLearnButtonActive}
          setShowLearnNavigation={setShowLearnNavigation}
          isFilledHeader={shouldRenderFilledHeader()}
          hasAlertBanner={showAlertBanner}
          hasSplitBanner={showSplitBanner}
        />
      </div>

      <main
        className={clsx(styles.content, {
          [styles.contentLandingPage]: window.location.pathname === '/',
          [styles.isGreyLayout]: isGreyLayout,
          [styles.hasAlertBannerOnLandingPage]:
            hasAlertBanner &&
            REACT_APP_COMPANY === 'HX' &&
            window.location.pathname === '/',
        })}
      >
        {children}
      </main>
      {window.location.href.includes('training/module/') ? (
        <div id="mainFooter" />
      ) : (
        <MainFooter />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Layout;
