import { useEffect, useState } from 'react';
import { Header3 } from 'components/UI/Brand/text';
import translate from 'translate';
import FilterSearchBar from 'components/Search/SearchResult/FilterSearchResultBar/FilterSearchBar';
import SortingMenu from 'components/Search/SearchResult/SortingMenu/SortingMenu';
import { COASTAL_OPTIONS } from 'utils/constants';
// The situation: we send a promo code to PG. PG returns a different promo code instead of the original one.
// As a result, we are unable to compare the two codes and inform the user that the promo code has been applied.
// Therefore, to prevent confusion, we hide the component until PG completes the investigation.
// import SearchResultInfoBanner from 'containers/Book/Search/SearchResultInfoBanner';
import { ICabinBaseSelector } from 'interfaces/ICabin';
import { AlertBox, Icon } from 'components';
import { ITour } from 'interfaces/ITour';
import { differenceInDays, fromUnixTime } from 'date-fns';
import styles from './styles.module.scss';

interface ISearchResultHeader {
  accessibleCabinsCount: number;
  isAccessbilityRequested: boolean;
  sortingParam: string;
  setSortingParam: (value: string) => void;
  voyageType: COASTAL_OPTIONS;
  isCoastal: boolean;
  toursCount: number;
  startDate: number;
  endDate: number;
  deckSpace: ICabinBaseSelector;
  isOttoSverdup: ITour;
}

const SearchResultHeader = ({
  accessibleCabinsCount,
  isAccessbilityRequested,
  setSortingParam,
  sortingParam,
  toursCount,
  voyageType,
  startDate,
  endDate,
  deckSpace,
  isCoastal,
  isOttoSverdup,
}: ISearchResultHeader) => {
  const [accessibleLabel, setAccessibleLabel] = useState<string>(null);

  const dayDifference = differenceInDays(
    fromUnixTime(endDate),
    fromUnixTime(startDate)
  );

  const hasShowAccessibilityFilter =
    toursCount > 0 &&
    (!(voyageType === COASTAL_OPTIONS.P2P && deckSpace !== null) ||
      voyageType !== COASTAL_OPTIONS.P2P);

  useEffect(() => {
    if (isAccessbilityRequested) {
      const label =
        accessibleCabinsCount > 1
          ? isCoastal
            ? 'Accessible_CruisePlural'
            : 'Accessible_Voyages'
          : isCoastal
            ? 'Accessible_Cruise'
            : 'Accessible_Voyage';
      setAccessibleLabel(label);
    }
  }, [accessibleCabinsCount, isAccessbilityRequested, isCoastal]);

  const getResultTitle = () =>
    isAccessbilityRequested
      ? translate(
          accessibleLabel,
          accessibleCabinsCount,
          isCoastal ? dayDifference : ''
        )
      : translate(
          toursCount > 1
            ? isCoastal
              ? 'SearchResult_CoastalTitlePlural'
              : 'SearchResult_TitlePlural'
            : isCoastal
              ? 'SearchResult_CoastalTitleSingle'
              : 'SearchResult_TitleSingle',
          toursCount,
          isCoastal ? dayDifference : ''
        );

  return (
    <div className={styles.root}>
      {/* <SearchResultInfoBanner /> */}
      <div className={styles.titleContainer}>
        <Header3 className={styles.resultSummary}>{getResultTitle()}</Header3>
        <SortingMenu
          sortingParam={sortingParam}
          setSortingParam={setSortingParam}
        />
      </div>
      {isOttoSverdup && !isCoastal && (
        <AlertBox
          className={styles.infoBox}
          type="info"
          OverrideIcon={<Icon iconType="circleWithExclamation" />}
        >
          {translate('Search_Alert_Otto')}
        </AlertBox>
      )}
      <div className={styles.filterContainer}>
        <FilterSearchBar
          allCount={toursCount}
          accessibleCount={accessibleCabinsCount}
          hasShowAccessibilityFilter={hasShowAccessibilityFilter}
        />
      </div>
    </div>
  );
};

export default SearchResultHeader;
