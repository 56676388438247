import translate from 'translate';
import { useGetHomePageQuery } from 'store/services/home';
import styled from 'styled-components';
import { Icon } from 'components';
import { colors } from 'style/colors';
import CustomCarousel from '../CustomCarousel/CustomCarousel';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.h3`
  font-family: 'Atlas Grotesk Medium';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.33;
  color: ${colors.shared.black};
  margin-bottom: 32;
`;

const Description = styled.p`
  font-family: 'Atlas Grotesk Regular';
  font-size: 14;
  font-weight: normal;
  line-height: 1.71;
  color: ${colors.shared.warmGray6};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const transformImages = (images: string[]) => {
  if (!images) return [];
  return images.map((image) => ({ imageUrl: `${image}` }));
};
const AccessibleCabinInfoModal = ({
  onModalClose,
}: {
  onModalClose: () => void;
}) => {
  const { data: homeData } = useGetHomePageQuery();
  const info = homeData ? homeData.accessibleCabinInformation : null;

  return (
    <div id="accessible_info_modal">
      <Container>
        <Header>
          <Title>{translate('AccessibleModal_Title')}</Title>
          <CloseButton
            onClick={onModalClose}
            aria-label={translate('Search_MobileSearchBar_Close')}
          >
            <Icon
              iconType="cross"
              color={colors.shared.black}
              width={24}
              height={24}
            />
          </CloseButton>
        </Header>
        <div>
          <CustomCarousel
            imageData={transformImages(
              info && info.gallery ? info.gallery : null
            )}
          />
        </div>
        <Description>
          {info && info.description ? info.description : ''}
        </Description>
      </Container>
    </div>
  );
};

export default AccessibleCabinInfoModal;
