import { ReactNode } from 'react';

const isString = (value: unknown) =>
  typeof value === 'string' || value instanceof String;

const escapeRegExp = (value: string) => {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExpChar = RegExp(reRegExpChar.source);

  return value && reHasRegExpChar.test(value)
    ? value.replace(reRegExpChar, '\\$&')
    : value;
};

const isRegExp = (expression: unknown) => expression instanceof RegExp;

function replaceString(
  string: string,
  match: string,
  fn: (match: string, index: number, offset: number) => ReactNode
) {
  let curCharStart = 0;
  let curCharLen = 0;

  let expression = null;

  if (!isRegExp(match)) {
    expression = new RegExp(`(${escapeRegExp(match)})`, 'gi');
  }

  const result: ReactNode[] = string.split(expression);

  // Apply fn to all odd elements
  for (let i = 1, { length } = result; i < length; i += 2) {
    /** @see {@link https://github.com/iansinnott/react-string-replace/issues/74} */
    if (result[i] === undefined || result[i - 1] === undefined) {
      console.warn(
        'reactStringReplace: Encountered undefined value during string replacement. Your RegExp may not be working the way you expect.'
      );
      // eslint-disable-next-line no-continue
      continue;
    }

    if (typeof result[i] === 'string') {
      const element = result[i] as string;
      curCharLen = element.length;
      curCharStart += (result[i - 1] as string).length;
      result[i] = fn(element, i, curCharStart);
    }
    curCharStart += curCharLen;
  }

  return result;
}

export function reactStringReplace(
  text: string | ReactNode[],
  match: string,
  fn: (match: string, index: number, offset: number) => ReactNode
) {
  let values = text;
  if (!Array.isArray(values)) {
    values = [values];
  }

  return values
    .flat()
    .map((value) =>
      isString(value) ? replaceString(value as string, match, fn) : value
    );
}
