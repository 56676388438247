import { SVGProps } from 'react';

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M15.55 31.1c8.589 0 15.55-6.963 15.55-15.55C31.1 6.961 24.14 0 15.55 0S0 6.962 0 15.55C0 24.136 6.96 31.1 15.55 31.1"
      />
      <path
        fill="#232120"
        d="M21.812 20.32c0 .822-.668 1.49-1.49 1.49h-9.54c-.822 0-1.49-.668-1.49-1.49v-6.366h2.321a4.238 4.238 0 0 0-.312 1.596 4.257 4.257 0 0 0 4.251 4.253 4.257 4.257 0 0 0 4.251-4.253c0-.564-.113-1.102-.312-1.596h2.321v6.366zm-8.479-6.366a2.733 2.733 0 0 1 2.219-1.141c.913 0 1.722.451 2.219 1.14.325.45.518 1.001.518 1.597a2.74 2.74 0 0 1-2.737 2.737 2.74 2.74 0 0 1-2.736-2.737c0-.596.193-1.146.517-1.596zm7.851-4.387h.344v2.635l-2.628.008-.009-2.636 2.293-.007zm-.862-1.792h-9.54a3.009 3.009 0 0 0-3.005 3.005v9.54a3.009 3.009 0 0 0 3.005 3.005h9.54a3.009 3.009 0 0 0 3.005-3.005v-9.54a3.009 3.009 0 0 0-3.005-3.005z"
      />
    </g>
  </svg>
);

export default InstagramIcon;
