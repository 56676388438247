import { handleActions } from 'redux-actions';
import * as actions from '../actions/registration';

const initialState = {
  registrationResponse: null,
  error: null,
  loading: false,
  isLead: false,
};

const reducer = handleActions(
  {
    [actions.leadCreated]: (state) => ({
      ...state,
      isLead: true,
    }),
    [actions.registrationStart]: () => ({
      ...initialState,
      loading: true,
    }),
    [actions.registrationSuccess]: (state, action) => ({
      ...state,
      registrationResponse: action.payload.registrationResponse,
      loading: false,
    }),
    [actions.registrationFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [actions.registrationClear]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
