import React, { ErrorInfo } from 'react';
import Icon from 'components/UI/Icons/Icon';
import { DEFAULT_SUPPORT_EMAIL, getSupportEmail } from 'utils/supportInfo';
import { TrackJS } from 'trackjs';
import CustomLink from 'components/UI/CustomLink/CustomLink';
import translate from 'translate';
import { colors } from 'style/colors';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IErrorBoundaryState {
  hasError: boolean;
  supportEmail: string;
}

interface IErrorBoundaryProps {
  children: React.ReactNode;
  setIsAppError: (value: boolean) => void;
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      supportEmail: DEFAULT_SUPPORT_EMAIL,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    getSupportEmail().then((supportEmail) => this.setState({ supportEmail }));
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.setIsAppError(true);
    if (errorInfo && errorInfo.componentStack) {
      // The component stack is sometimes useful in development mode
      // In production it can be somewhat obfuscated, so feel free to omit this line.
      console.warn(errorInfo.componentStack);
    }

    TrackJS.track(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.title}>{translate('GeneralError_Title')}</div>
          <div className={styles.alertIcon}>
            <Icon
              iconType="circleWithExclamation"
              width={80}
              height={80}
              color={colors.shared.warmGray6}
            />
          </div>
          <div className={styles.description}>
            {translate(
              'GeneralError_Description',
              <CustomLink
                onClick={() => {
                  window.location.href = `mailto: ${this.state.supportEmail}`;
                }}
              >
                {translate('GeneralError_Link')}
              </CustomLink>
            )}
          </div>
          <div className={styles.refreshButtonContainer}>
            <PrimaryButton
              onClick={() => {
                window.location.reload();
              }}
            >
              {translate('GeneralError_RefreshButton')}
            </PrimaryButton>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
